import React, { Component } from "react";

import { connect } from "react-redux";
import { lotteryChange } from "../../redux/actions/lotterySelectorAction";
import { addToCart, removeFromCart } from "../../redux/actions/cartAction";
import { userChange } from "../../redux/actions/userAction";
import { Row, Col, Button, Input,CustomInput } from "reactstrap";


import { withRouter } from "react-router-dom";
import { ModalMultiSelect } from "../../components/Vietluck/ModalVietluck";
import apiMyAccount from "../../api/MyAccount";
import utils from "../../utils";
import moment from "moment";
class CountDown extends React.Component {
  constructor(props) {
    super(props);
    this.countDownDate = 0;
    this.state = {
      count_down: '00:00:00'
    };
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !utils.deepCompareObj(this.state, nextState);
  }

  componentDidMount() {
    clearInterval(this.countDownInterval);
    const that = this;
    var end=moment(this.props.date,'DD-MM-YYYY HH:mm:ss').add(-1,'minutes')
    this.countDownInterval = setInterval(function () {
      const timeLeft = moment(end.diff(moment())).utc();           
      const formatted = timeLeft.format('HH:mm:ss');     
      console.log(end.diff(moment()))
      if (
        end.diff(moment())<=0
      ) {
        utils.confirm(
          `Thời gian mua vé kỳ #${that.props.term} đã hết!`,
          (check) => {
            window.location.reload();
          }
        );
      }
      that.setState({ count_down: formatted });
    }, 1000);
  }

  componentWillUnmount() {
    if (this.countDownInterval) {
      clearInterval(this.countDownInterval);
    }
  }

  timeGenerate() {
    let count_down = JSON.parse(JSON.stringify(this.state.count_down));
    Object.keys(count_down).forEach((key) => {
      count_down[key] =
        count_down[key] > 9 ? count_down[key] : "0" + count_down[key];
    });
    return `${count_down.hours}:${count_down.minutes}:${count_down.seconds}`;
  }

  render() {
    return (
      <div
        style={{ fontSize: "0.875rem" }}
        className="d-flex justify-content-center w-100"
      >
        <span>Thời gian mua vé kỳ #{this.props.term} còn: </span>
        <span>{this.state.count_down}</span>
      </div>
    );
  }
}

class MainLottery extends Component {
  constructor(props) {
    super(props);
    this.productOptionArray = this.props.lotterySelector.productOptionArray.filter(e=>e.id!=95 && e.id!=96);
    this.termArray = this.props.lotterySelector.termArray;
    this.state = {};
  }

  // shouldComponentUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any): boolean {
  //     return nextProps.lotterySelector !== this.props.lotterySelector;
  // }

  handleChangeProductOption(productOptionId) {
    this.props.lotteryChange({
      productOptionId: parseInt(productOptionId),
      isChangeKenoBao: this.props.isKenoBao,
    });
    // if(this.props.isKenoBao){
    //     let kenoType=this.props.lotterySelector;
    //     this.props.lotteryChange({ productOptionKenoTypeId: (this.props.lotterySelector.productOptionKenoTypeArray[1].id), });
    // }
  }

  handleChangeProductOptionKenoType(productOptionKenoTypeId) {
    this.props.lotteryChange({
      productOptionKenoTypeId: parseInt(productOptionKenoTypeId),
    });
  }

  handleChangeTerm(value) {
    let isTC=0
    this.props.lotterySelector.value.map(e=>{
        if(e.number[0]=='TC'){
            isTC=1;
            return;
        }
    })
    if(isTC==1){
        utils.alert("Vietluck hiện chưa hỗ trợ tính năng chọn kỳ cho vé TC!");
        return;
    }
    value = parseInt(value);
    let termChecked = Array.copyState(this.props.lotterySelector.term);
    let index = termChecked.indexOf(value);

    if (index !== -1) {
      if (termChecked.length <= 1) {
        return;
      }
      termChecked.splice(index, 1);
    } else {
      termChecked.push(value);
    }
    this.props.lotteryChange({ term: termChecked });
  }
  handleTCAll(){
    this.props.handleTCAll();
}
  handleRandom() {
    this.props.onRandom();
  }

  handleAddToCart() {
	if(this.props.lotterySelector.productId!==6){
      if(this.props.cartData.length>=10){
        utils.confirm(
          `Giỏ hàng đã đầy`,
          (check) => {
            
          }
        );
      }
    }
    let data = {
      productId: this.props.lotterySelector.productId,
      productOptionId: this.props.lotterySelector.productOptionId,
      productOptionKenoTypeId: this.props.lotterySelector
        .productOptionKenoTypeId,
      value: this.props.lotterySelector.value.filter(
        (dt) => dt.number.length > 0
      ),
      termArray: this.props.lotterySelector.term,
      termArrayWithDate: this.props.lotterySelector.termArray.filter((dt) =>
        this.props.lotterySelector.term.includes(dt.termValue)
      ),
      totalPrice: this.getPrice(),
    };
    this.props.lotterySelector.value.map(e=>{
      if(e.number[0]=='TC'){
          data.isTC=1;
          return;
      }
  })
    if (data.value.length === 0) {
      // Array.Toast.ToastCenterShort('Bạn chưa chọn vé nào');
      return false;
    }
    this.props.addToCart(data);
    let value_reset = JSON.parse(
      JSON.stringify(this.props.lotterySelector.value)
    );
    for (let i = 0; i < value_reset.length; i++) {
      value_reset[i].number = [];
    }
    this.props.lotteryChange({ value: value_reset });
    // Array.Toast.ToastCenterShort('Đã thêm vào giỏ hàng');
    return true;
  }

  handleBuyNow() {
    let pr = JSON.parse(JSON.stringify(this.props.cartData));
        pr.forEach(product => {
            //if (product.productId === 6) {
            this.props.removeFromCart(product.id);
            //}
        });
    if (this.handleAddToCart()) {
      // this.props.navigationObj.navigate('Cart', this.props.lotterySelector.productId === 6 ? "keno" : null);
      if (this.props.lotterySelector.productId === 6) {
        this.props.history.push("/payment#keno");
        // this.props.history.push("/payment#keno");
      } else {
        this.props.history.push("/payment");
        // this.props.history.push("/cart");
      }
    }
  }

  getGain(number) {
    let productId = this.props.lotterySelector.productId;
    let productOptionId = this.props.lotterySelector.productOptionId;
    let productOptionKenoTypeId = this.props.lotterySelector
      .productOptionKenoTypeId;
    switch (productId) {
      case 1:
      case 2:
      case 4:
      case 5:
        return 1;
      case 6: // Keno
        if (productOptionId !== 82) {
          // Nếu khác Chẵn lẻ
          if (productOptionKenoTypeId) {
            // Nếu chọn vé bao
            return utils.combination(
              productOptionId - 70,
              productOptionKenoTypeId
            ); // Tổ hợp
          }
        }
        return 1;
      case 3:
        switch (productOptionId) {
          case 43:
          case 44:
            return 10;
          case 42:
            let num = number.split("");
            let count = [0, 0, 0, 0];
            for (let i = 0; i < num.length; i++) {
              count[i] = num.filter((x) => x === num[i]).length;
            }

            if (count.indexOf(3) !== -1) {
              return 4;
            }

            if (
              count.indexOf(2) !== -1 &&
              count.reduce((a, b) => a + b, 0) === 8
            ) {
              return 6;
            }

            if (
              count.indexOf(2) !== -1 &&
              count.reduce((a, b) => a + b, 0) !== 8
            ) {
              return 12;
            }

            if (count.indexOf(1) !== -1) {
              return 24;
            }
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
    return 1;
  }

  getPrice() {
    let value = Array.copyState(this.props.lotterySelector.value);
    let term = Array.copyState(this.props.lotterySelector.term);
    let totalPrice = 0;
    for (let i = 0; i < 6; i++) {
      if (value[i].number.length > 0) {
        totalPrice =
          totalPrice + value[i].price * this.getGain(value[i].number[0]);
      }
    }
    return totalPrice * term.length;
  }

  toggle(component) {
    this.setState({
      [component]: !this.state[component],
    });
  }
  toggleSendMail=()=>{
    
    let data={
      id:this.props.user.id,
      isSendMail:this.props.user.isSendMail?!this.props.user.isSendMail:true
    }
    apiMyAccount.editUserInfo(data, (err, result) => {
      if (err) {
        this.setState({ isLoading: false });
        console.log(err);
        //utils.alert(err.response.data._error_message);
      } else {
        this.setState({ isLoading: false });
        let user = utils.copyState(this.props.user);
        user.isSendMail=data.isSendMail
        this.props.userChange(user);
        utils.alert("Thay đổi của bạn đã được lưu lại!");
      }
    });
  }
  render() {
    return (
      <div className="main-lottery lottery-page">
        <div className="h-100 overflow-hidden d-flex flex-column">
          <div className="px-3 py-2 main-lottery__header">
            <Col>
              <Row>
                <span className="font-weight-bold">
                  Số dư tài khoản:{" "}
                  <span className="text-danger">
                    {(this.props.user.availableBalance * 1000).getMoneyFormat()}{" "}
                    ₫
                  </span>
                </span>
              </Row>
              <Row style={{flex:1}}>
              {/* {this.props.lotterySelector.productId !== 6 && (
           
                <CustomInput         
                  id={'chkMail'}           
                    type={"checkbox"}
                    checked={this.props.user.isSendMail}
                    label={"Nhận thông báo qua Email"}
                    className="my-1 "
                    name="price"   
                    onChange={()=>this.toggleSendMail()}           
                />
               
              )} */}
            </Row>
              <Row className="font-weight-bold">
                <Col>
                  <Row>Cách chơi</Row>
                  <Row>
                    <Input
                      type="select"
                      style={{ width: "90%" }}
                      onChange={(event) => {
                        this.handleChangeProductOption(event.target.value);
                      }}
                    >
                      {this.productOptionArray.map(({ id, name }) => (
                        <option key={id.toString()} value={id}>
                          {name}
                        </option>
                      ))}
                    </Input>
                  </Row>
                </Col>
                <Col>
                  <Row>Kỳ mua</Row>
                  <Row>
                    <ModalMultiSelect
                      checked={this.props.lotterySelector.term}
                      title={"Chọn kỳ mua"}
                      btnToggleStyle={{ width: "90%" }}
                      data={this.termArray}
                      keyId={"termValue"}
                      keyLabel={"termString"}
                      keySubLabel={"date"}
                      onChange={this.handleChangeTerm.bind(this)}
                    />
                  </Row>
                </Col>
              </Row>
              {this.props.lotterySelector.productId === 6 &&
                this.props.isKenoBao &&
                this.props.lotterySelector.productOptionId !== 82 && (
                  <Row className="mt-2">
                    <Input
                      type="select"
                      value={this.props.lotterySelector.productOptionKenoTypeId}
                      onChange={(event) =>
                        this.handleChangeProductOptionKenoType(
                          event.target.value
                        )
                      }
                    >
                      {this.props.lotterySelector.productOptionKenoTypeArray.map(
                        ({ id, name }, index) => (
                          <option key={index} value={id}>
                            {name}
                          </option>
                        )
                      )}
                    </Input>
                  </Row>
                )}
            </Col>
          </div>
          <Row className="main-lottery__content">
            <Col className="mx-3 pb-5 pt-2">
              <Row>{this.props.children}</Row>
            </Col>
          </Row>
        </div>
        <div className="main-lottery__footer">
          <Col>
            <Row>
              {this.props.lotterySelector.productId === 6 && (
                <CountDown
                  term={this.props.lotterySelector.termArray[0].termValue}
                  date={this.props.lotterySelector.termArray[0].date}
                />
              )}
            </Row>
            
            <Row className="justify-content-end">
              <span className="mx-2 font-weight-bold">
                Tạm tính:{" "}
                <span className="text-danger">
                  {(this.getPrice() * 1000).getMoneyFormat()} ₫
                </span>
              </span>
            </Row>
            <Row>
            {
              (this.props.lotterySelector.productId==1 || 
                                        this.props.lotterySelector.productId==2 || 
                                        this.props.lotterySelector.productId==4 || 
                                        this.props.lotterySelector.productId==5 || 
                                        (this.props.lotterySelector.productId==6 && this.props.lotterySelector.productOptionId!==82 &&!this.props.isKenoBao) )?
              <>
                  <Button
                    color="warning"
                    className="w-100 border-radius-none py-2"
                    onClick={this.handleRandom.bind(this)}
                  >
                    CHỌN NGẪU NHIÊN
                  </Button>
                 
                </>
             : <Button
                color="warning"
                className="w-100 border-radius-none py-2"
                onClick={this.handleRandom.bind(this)}
              >
                CHỌN NGẪU NHIÊN
              </Button>
            }
            </Row>
            <Row className="w-100">
              {this.props.lotterySelector.productId === 6 ? (
                <Button
                  color="danger"
                  className="w-100 border-radius-none py-2"
                  onClick={this.handleBuyNow.bind(this)}
                >
                  MUA NGAY
                </Button>
              ) : (
                <>
                 
                  <Button
                    color="danger"
                    className="w-100 border-radius-none py-2"
                    onClick={this.handleBuyNow.bind(this)}
                  >
                    MUA NGAY
                  </Button>
                </>
              )}
            </Row>
          </Col>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    lotterySelector: state.lotterySelector,
    cartData: state.cartData.cart,
    user: state.user,
  };
}

export default withRouter(connect(mapStateToProps, { lotteryChange, addToCart, removeFromCart })(MainLottery));
