import React, { Component } from 'react';
import NumberCircle from "../../components/Vietluck/NumberCircle";
import { Col, Row } from "reactstrap";

import LoadingPage from "../../components/LoadingPage";
import ButtonLoading from "../../components/FworkTag/ButtonLoading";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs"

import apiResult from "../../api/Result";
import { Link } from "react-router-dom";
import { Grid, HardDrive } from "react-feather";

export class ResultPower655Card extends React.Component {
    render() {
        return (
            <div className="d-flex flex-wrap justify-content-center w-100">
                {
                    this.props.result.result.map((num, index) => (
                        <NumberCircle
                            key={index}
                            title={num}
                            className="mr-1"
                            isBackground={index === 6}
                        />
                    ))
                }
            </div>
        )
    }
}

export class ResultMega645Card extends React.Component {
    render() {
        return (
            <div className="d-flex flex-wrap justify-content-center w-100">
                {
                    this.props.result.result.map((num, index) => (
                        <NumberCircle
                            key={index}
                            title={num}
                            className="mr-1"
                        />
                    ))
                }
            </div>
        )
    }
}

export class ResultMax4DCard extends React.Component {
    render() {
        return (
            <React.Fragment>

            </React.Fragment>
        )
    }
}

export class ResultMax3DCard extends React.Component {
    render() {
        const result = this.props.result.result;
        const firstPrize = result.find(dt => dt.type === 'giainhat');
        const secondPrize = result.find(dt => dt.type === 'giainhi');
        const thirdPrize = result.find(dt => dt.type === 'giaiba');
        const fourPrize = result.find(dt => dt.type === 'giaikhuyenkhich');
        return (
            <Row className="w-100 justify-content-center">
                <Col className="text-center">
                    <Row className="justify-content-center font-weight-bold">
                        <span>Giải nhất</span>
                    </Row>
                    <Row className="justify-content-center">
                        {
                            firstPrize.result.map((number, index) => (
                                <>
                                    {
                                        number.split('').map((num, index2) => (
                                            <NumberCircle
                                                title={num}
                                                key={`${index}-${index2}`}
                                                className="number-circle-sm"
                                            />
                                        ))
                                    }
                                    {
                                        index !== (firstPrize.result.length - 1) &&
                                        <div style={{ width: 10 }}>

                                        </div>
                                    }
                                </>
                            ))
                        }
                    </Row>
                    <Row className="justify-content-center mt-2 font-weight-bold">
                        <span>Giải nhì</span>
                    </Row>
                    <Row className="justify-content-center">
                        {
                            secondPrize.result.map((number, index) => (
                                <>
                                    {
                                        number.split('').map((num, index2) => (
                                            <NumberCircle
                                                title={num}
                                                key={`${index}-${index2}`}
                                                className="number-circle-sm"
                                            />
                                        ))
                                    }
                                    {
                                        index !== (secondPrize.result.length - 1) &&
                                        <div style={{ width: 10 }}>

                                        </div>
                                    }
                                </>
                            ))
                        }
                    </Row>
                    <Row className="justify-content-center mt-2 font-weight-bold">
                        <span>Giải ba</span>
                    </Row>
                    <Row className="justify-content-center">
                        {
                            thirdPrize.result.map((number, index) => (
                                (index < 3) &&
                                <>
                                    {
                                        number.split('').map((num, index2) => (
                                            <NumberCircle
                                                title={num}
                                                key={`${index}-${index2}`}
                                                className="number-circle-sm"
                                            />
                                        ))
                                    }
                                    {
                                        // index !== (thirdPrize.result.length - 1) &&
                                        <div style={{ width: 10 }}>

                                        </div>
                                    }
                                </>
                            ))
                        }
                    </Row>
                    <Row className="justify-content-center mt-1">
                        {
                            thirdPrize.result.map((number, index) => (
                                (index >= 3) &&
                                <>
                                    {
                                        number.split('').map((num, index2) => (
                                            <NumberCircle
                                                title={num}
                                                key={`${index}-${index2}`}
                                                className="number-circle-sm"
                                            />
                                        ))
                                    }
                                    {
                                        <div style={{ width: 10 }}>

                                        </div>
                                    }
                                </>
                            ))
                        }
                    </Row>
                    <Row className="justify-content-center mt-2 font-weight-bold">
                        <span>Giải khuyến khích</span>
                    </Row>
                    <Row className="justify-content-center">
                        {
                            fourPrize.result.map((number, index) => (
                                (index < 4) &&
                                <>
                                    {
                                        number.split('').map((num, index2) => (
                                            <NumberCircle
                                                title={num}
                                                key={`${index}-${index2}`}
                                                className="number-circle-sm"
                                            />
                                        ))
                                    }
                                    {
                                        <div style={{ width: 10 }}>

                                        </div>
                                    }
                                </>
                            ))
                        }
                    </Row>
                    <Row className="justify-content-center mt-1">
                        {
                            fourPrize.result.map((number, index) => (
                                (index >= 4) &&
                                <>
                                    {
                                        number.split('').map((num, index2) => (
                                            <NumberCircle
                                                title={num}
                                                key={`${index}-${index2}`}
                                                className="number-circle-sm"
                                            />
                                        ))
                                    }
                                    {
                                        <div style={{ width: 10 }}>

                                        </div>
                                    }
                                </>
                            ))
                        }
                    </Row>
                </Col>
            </Row>
        )
    }
}
export class ResultMax3DProCard extends React.Component {
    render() {
      const result = this.props.result.result;
      const firstPrize = result.find((dt) => dt.type === "giaidacbiet");
      const secondPrize = result.find((dt) => dt.type === "giainhat");
      const thirdPrize = result.find((dt) => dt.type === "giainhi");
      const fourPrize = result.find((dt) => dt.type === "giaiba");
      return (
        <Row className="w-100 justify-content-center">
          <Col className="text-center">
            <Row className="justify-content-center font-weight-bold">
              <span>Giải đặc biệt</span>
            </Row>
            <Row className="justify-content-center">
              {firstPrize.result.map((number, index) => (
                <>
                  {number.split("").map((num, index2) => (
                    <NumberCircle
                      title={num}
                      key={`${index}-${index2}`}
                      className="number-circle-sm"
                    />
                  ))}
                  {index !== firstPrize.result.length - 1 && (
                    <div style={{ width: 10 }}></div>
                  )}
                </>
              ))}
            </Row>
            <Row className="justify-content-center mt-2 font-weight-bold">
              <span>Giải nhất</span>
            </Row>
            <Row className="justify-content-center">
              {secondPrize.result.map((number, index) => (
                <>
                  {number.split("").map((num, index2) => (
                    <NumberCircle
                      title={num}
                      key={`${index}-${index2}`}
                      className="number-circle-sm"
                    />
                  ))}
                  {index !== secondPrize.result.length - 1 && (
                    <div style={{ width: 10 }}></div>
                  )}
                </>
              ))}
            </Row>
            <Row className="justify-content-center mt-2 font-weight-bold">
              <span>Giải nhì</span>
            </Row>
            <Row className="justify-content-center">
              {thirdPrize.result.map(
                (number, index) =>
                  index < 3 && (
                    <>
                      {number.split("").map((num, index2) => (
                        <NumberCircle
                          title={num}
                          key={`${index}-${index2}`}
                          className="number-circle-sm"
                        />
                      ))}
                      {
                        // index !== (thirdPrize.result.length - 1) &&
                        <div style={{ width: 10 }}></div>
                      }
                    </>
                  )
              )}
            </Row>
            <Row className="justify-content-center mt-1">
              {thirdPrize.result.map(
                (number, index) =>
                  index >= 3 && (
                    <>
                      {number.split("").map((num, index2) => (
                        <NumberCircle
                          title={num}
                          key={`${index}-${index2}`}
                          className="number-circle-sm"
                        />
                      ))}
                      {<div style={{ width: 10 }}></div>}
                    </>
                  )
              )}
            </Row>
            <Row className="justify-content-center mt-2 font-weight-bold">
              <span>Giải ba</span>
            </Row>
            <Row className="justify-content-center">
              {fourPrize.result.map(
                (number, index) =>
                  index < 4 && (
                    <>
                      {number.split("").map((num, index2) => (
                        <NumberCircle
                          title={num}
                          key={`${index}-${index2}`}
                          className="number-circle-sm"
                        />
                      ))}
                      {<div style={{ width: 10 }}></div>}
                    </>
                  )
              )}
            </Row>
            <Row className="justify-content-center mt-1">
              {fourPrize.result.map(
                (number, index) =>
                  index >= 4 && (
                    <>
                      {number.split("").map((num, index2) => (
                        <NumberCircle
                          title={num}
                          key={`${index}-${index2}`}
                          className="number-circle-sm"
                        />
                      ))}
                      {<div style={{ width: 10 }}></div>}
                    </>
                  )
              )}
            </Row>
          </Col>
        </Row>
      );
    }
  }
export class ResultKenoCard extends React.Component {
    render() {
        let result = this.props.result.result;
        let val = [
            result.filter(dt => dt % 2 === 0).length,
            result.filter(dt => dt % 2 !== 0).length,
            result.filter(dt => dt > 40).length,
            result.filter(dt => dt <= 40).length,
        ];
        return (
            <div className="result-keno-card">
                <div className="d-flex flex-wrap justify-content-center w-100">
                    {
                        this.props.result.result.map((num, index) => (
                            <NumberCircle
                                key={index}
                                title={num}
                                className="mr-1 mt-1"
                            />
                        ))
                    }
                </div>
                <Row className="mt-2 keno-2">
                    <Col className="text-center">
                        <span
                            className="left-container"
                            style={
                                val[0] >= 13 ? {
                                    backgroundColor: 'blue',
                                    color: 'white',
                                } : {}
                            }
                        >
                            Chẵn ({val[0]})
                        </span>
                        <span
                            className="right-container"
                            style={
                                val[1] >= 13 ? {
                                    backgroundColor: 'blue',
                                    color: 'white',
                                } : {}
                            }
                        >
                            Lẻ ({val[1]})
                        </span>
                    </Col>
                    <Col className="text-center">
                        <span
                            className="left-container"
                            style={
                                val[2] >= 13 ? {
                                    backgroundColor: 'blue',
                                    color: 'white',
                                } : {}
                            }
                        >
                            Lớn ({val[2]})
                        </span>
                        <span
                            className="right-container"
                            style={
                                val[3] >= 13 ? {
                                    backgroundColor: 'blue',
                                    color: 'white',
                                } : {}
                            }
                        >
                            Nhỏ ({val[3]})
                        </span>
                    </Col>
                </Row>
            </div>
        )
    }
}

export class ResultDienToan extends React.Component{
    render(){
        let result=this.props.result.result;
        let dacbiet=result.find(r=>r.type=='dacbiet').result;
        let giainhat=result.find(r=>r.type=='giainhat').result;
        let giainhi=result.find(r=>r.type=='giainhi').result;
        let giaiba=result.find(r=>r.type=='giaiba').result;
        let giaitu=result.find(r=>r.type=='giaitu').result;
        let giainam=result.find(r=>r.type=='giainam').result;
        let giaisau=result.find(r=>r.type=='giaisau').result;
        let giaibay=result.find(r=>r.type=='giaibay').result;
        return(
            <Row className="w-100">
                <Col className="text-center">                   
                    <Row className="result-dien-toan">
                    <table class="table_center table">
                        <tbody>
                            <tr >
                                <td className="side font-weight-bold">Đặc biệt</td>
                                <td colspan="13" className='res-dt-special'>{dacbiet[0]}</td>
                            </tr>
                            <tr >
                                <td className="side font-weight-bold">Giải nhất</td>
                                <td colspan="13" >{giainhat[0]}</td>
                            </tr>
                            <tr >
                                <td className="side font-weight-bold">Giải nhì</td>
                                <td colspan="8" >{giainhi[0]}</td>
                                <td colspan="8" >{giainhi[1]}</td>
                            </tr>
                            
                            <tr >
                                <td className="side font-weight-bold" rowSpan='2'>Giải ba</td>
                                {
                                    giaiba.slice(0, 3).map(res=>(
                                        <td colSpan='5' >{res}</td>)
                                    )
                                }                                
                            </tr>
                            <tr >                               
                                {
                                    giaiba.slice(3, 6).map(res=>(
                                        <td colSpan='5' >{res}</td>)
                                    )
                                }                                
                            </tr>
                            
                            <tr>
                                <td className="side font-weight-bold" >Giải tư</td>
                                {
                                    giaitu.map(res=>(
                                        <td colSpan='4' >{res}</td>)
                                    )
                                } 
                            </tr>
                            <tr>
                                <td className="side font-weight-bold" rowSpan='2'>Giải năm</td>
                                {
                                    giainam.slice(0, 3).map(res=>(
                                        <td colSpan='5' >{res}</td>)
                                    )
                                } 
                            </tr>
                            <tr >                               
                                {
                                    giainam.slice(3, 6).map(res=>(
                                        <td colSpan='5' >{res}</td>)
                                    )
                                }                                
                            </tr>
                            <tr>
                                <td className="side font-weight-bold" >Giải sáu</td>
                                {
                                    giaisau.map(res=>(
                                        <td colSpan='5' >{res}</td>)
                                    )
                                } 
                            </tr>
                            <tr>
                                <td className="side font-weight-bold" >Giải bảy</td>
                                {
                                    giaibay.map(res=>(
                                        <td colSpan='4' >{res}</td>)
                                    )
                                } 
                            </tr>
                        </tbody>
                    </table>
                    </Row>

                </Col>

            </Row>
        )
    }
}
export class Result6x36 extends React.Component{
    render() {
        return (
            <div className="d-flex flex-wrap justify-content-center w-100">
                {
                    this.props.result.result.map((num, index) => (
                        <NumberCircle
                            key={index}
                            title={num}
                            className="mr-1"
                        />
                    ))
                }
            </div>
        )
    }
}
export class ResultThantai extends React.Component{
    render() {
        return (
            <div className="d-flex flex-wrap justify-content-center w-100">
                {
                    this.props.result.result.map((num, index) => (
                        <NumberCircle
                            key={index}
                            title={num}
                            className="mr-1"
                        />
                    ))
                }
            </div>
        )
    }
}
export class Result123 extends React.Component{
    render() {
        return (
            <div className="d-flex flex-wrap justify-content-center w-100">
                {
                    this.props.result.result.map((number, i) => (                        
                        i===2
                                                        ?
                                                        <NumberCircle
                                                            key={i.toString()}
                                                            price={number}
                                                            className="hover-pointer"
                                                        />:
                                                i===1? <NumberCircle
                                                            size={32}
                                                            key={i.toString()}
                                                            title={number}
                                                            className="mr-1 number-circle-3  hover-pointer"
                                                        />
                                                        :
                                                        <NumberCircle
                                                            size={32}
                                                            key={i.toString()}
                                                            title={number}
                                                            className="mr-1 hover-pointer"
                                                        />
                    ))
                }
            </div>
        )
    }
}


class MainResult extends Component {

    constructor(props) {
        super(props);
        this.productId = 6;
        this.lastPeriodId = 0;
        this.state = {
          isLoaded: false,
          isLoading: false,
        };
      }
    
      componentDidMount() {
        this.handleRefresh();
      }
    
      handleSelectTab(index) {
        this.lastPeriodId = 0;
        if (index === 0) index = 6;
        else if (index === 3) index = 12;
        else if (index === 5) index = 7;
        else if (index === 6) index = 9;
        else if (index === 7) index = 10;
        else if (index === 8) index = 11;
        this.productId = index;
        console.log(index)
        this.handleRefresh();
      }
    
      handleRefresh() {
        this.setState({ isLoaded: false });
        apiResult.getLastPeriod(this.productId, (err, result) => {
          if (err) {
          } else {
            let id = result.id;
            this.lastPeriodId = id;
            apiResult.getResult(this.productId, [id - 9, id], (err, result) => {
              if (err) {
              } else {
                
                this.lastPeriodId = id - 10;
                if (this.productId < 7 || this.productId===12 )
                {
                 
                  result.reverse();
                }
              
                this.setState({ result: result, isLoaded: true });
              }
            });
          }
        });
      }
    
      handleLoadMore() {
        if (this.state.isLoading) {
          return;
        }
        this.setState({ isLoading: true });
        apiResult.getResult(
          this.productId,
          [this.lastPeriodId - 9, this.lastPeriodId],
          (err, result) => {
            if (err) {
              this.setState({ isLoading: false });
            } else {
              if (this.productId < 7 || this.productId===12) result.reverse();
              let last_result = JSON.parse(JSON.stringify(this.state.result));
              last_result = last_result.concat(result);
              this.setState({
                isLoading: false,
                result: last_result,
              });
              this.lastPeriodId -= 10;
            }
          }
        );
      }
    
      renderResultCard(result, key) {
        switch (this.productId) {
          case 1:
            return <ResultMega645Card result={result} key={key} />;
          case 2:
            return <ResultPower655Card result={result} key={key} />;
          case 3:
            return <ResultMax3DCard result={result} key={key} />;
          case 4:
          case 5:
            return <ResultMax3DCard result={result} key={key} />;
          case 6:
            return <ResultKenoCard result={result} key={key} />;
          case 7:
            return <ResultDienToan result={result} key={key} />;
          case 9:
            return <Result6x36 result={result} key={key} />;
          case 10:
            return <ResultThantai result={result} key={key} />;
          case 11: 
            return <Result123 result={result} key={key} />;
          case 12: 
            return <ResultMax3DProCard result={result} key={key} />;
          
          default:
            break;
        }
      }
    
      generateContent() {
        return (
          <>
            {this.state.result.map((result, index) => (
              <Col key={index}>
                <Row className="p-2 border-bottom">
                  <Col>
                    <Row className="mb-1">
                      <Col className="col-3">Kỳ: #{result.id}</Col>
                      <Col className="text-right">Ngày: #{result.date}</Col>
                    </Row>
                    {this.renderResultCard(result, index)}
                  </Col>
                </Row>
              </Col>
            ))}
            <ButtonLoading
              className="w-100"
              loading={this.state.isLoading}
              title="Xem thêm"
              onClick={this.handleLoadMore.bind(this)}
            />
          </>
        );
      }
      getSelected = () => {
        switch (this.productId) {
          case 6:
            return 0;
          case 7:
            return 5;
          case 9:
            return 6;
          case 10:
            return 7;
          case 11:
            return 8;
          case 12:
          return 3;
        }
        return this.productId;
      };
    render() {
        return (
            <div className="bootstrap-reset h-100">
                <div className="home-page bg-white">
                    <div className="h-100 overflow-auto">
                        {
                            this.state.isLoaded ?
                                <Tabs selectedIndex={this.getSelected()} onSelect={this.handleSelectTab.bind(this)}>
                                <TabList>
                    <Tab>Keno</Tab>
                    <Tab>Mega</Tab>
                    <Tab>Power</Tab>
                    <Tab>Max3D Pro</Tab>                    
                    <Tab>Max3D</Tab>
                   
                  </TabList>
                  <TabPanel>{this.generateContent()}</TabPanel>
                  <TabPanel>{this.generateContent()}</TabPanel>
                  <TabPanel>{this.generateContent()}</TabPanel>
                  <TabPanel>{this.generateContent()}</TabPanel>
                  <TabPanel>{this.generateContent()}</TabPanel>
                 
                </Tabs>
                                :
                                <LoadingPage />
                        }
                    </div>
                    <div className="home-page__footer py-2 d-flex">
                        <div className="w-50 text-center">
                            <Link to={"/"} className="link-unset">
                                <div>
                                    <Grid color={window.location.pathname === "/" ? "#169714" : "grey"} />
                                </div>
                                <div>
                                    <span style={{ fontSize: "0.875rem", color: window.location.pathname === "/" ? "#169714" : "grey" }}>Đặt vé</span>
                                </div>
                            </Link>
                        </div>
                        <div className="w-50 text-center">
                            <Link to={"/result"} className="link-unset">
                                <div>
                                    <HardDrive color={window.location.pathname === "/result" ? "#169714" : "grey"} />
                                </div>
                                <div>
                                    <span style={{ fontSize: "0.875rem", color: window.location.pathname === "/result" ? "#169714" : "grey" }}>Kết quả</span>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MainResult;
