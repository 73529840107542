import { combineReducers } from "redux";

import sidebar from "./sidebarReducers";
import layout from "./layoutReducer";
import theme from "./themeReducer";
import user from "./userReducer";
import lotterySelectorReducers from "./lotterySelectorReducers";
import lotteryConfigReducer from "./lotteryConfigReducer";
import cartReducers from "./cartReducers";
import { reducer as toastr } from "react-redux-toastr";

export default combineReducers({
  sidebar,
  layout,
  theme,
  user,
  toastr,
  lotterySelector: lotterySelectorReducers,
  lotteryConfig: lotteryConfigReducer,
  cartData: cartReducers
});
