import React, {Component} from 'react';

import {connect} from "react-redux";
import {removeFromCart} from "../../redux/actions/cartAction";

import {Link} from "react-router-dom";

import utils from "../../utils";

import {
    Col, Row,
    Button
} from "reactstrap"

import CartItem from "./CartItem";


class Cart extends Component {

    constructor(props) {
        super(props);
        this.isCartKeno = false
        this.state = {
            interactionsComplete: false,
            cartData: this.isCartKeno ? this.props.cartData.filter(dt => dt.productId === 6) : this.props.cartData.filter(dt => dt.productId !== 6),
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (!utils.deepCompareObj(this.props.cartData, nextProps.cartData)) {
            this.setState({
                cartData: this.isCartKeno ? nextProps.cartData.filter(dt => dt.productId === 6) : nextProps.cartData.filter(dt => dt.productId !== 6),
            });
        }

    };

    handleRemoveFromCart(id) {
        this.props.removeFromCart(id)
        // Alert.alert('Hủy vé', 'Đồng ý hủy vé này?', [
        //     {text: 'Đồng ý', onPress: () => this.props.removeFromCart(id)},
        //     {text: 'Không', type: 'cancel'},
        // ]);
    }

    getTotalPrice() {
        let totalPrice = 0;
        this.state.cartData.forEach(dt => {
            totalPrice += dt.totalPrice;
        });
        return totalPrice;
    }

    render() {
        return (
            <div className="bootstrap-reset h-100 cart-page">
                <div className="h-100 overflow-hidden d-flex flex-column">
                    {
                        this.state.cartData.length === 0 ?
                            <Row className="p-2">
                                <span>Giỏ hàng trống</span>
                            </Row>
                            :
                            <>
                                <Row className="h-100 overflow-auto">
                                    <Col className="p-3">
                                        {
                                            this.state.cartData.map((data, index) => (
                                                <CartItem
                                                    key={index}
                                                    data={data}
                                                    onRemove={this.handleRemoveFromCart.bind(this, data.id)}
                                                />
                                            ))
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Row className="justify-content-end px-2 font-weight-bold">
                                            <span>Tạm tính: <span className="text-danger">{(this.getTotalPrice() * 1000).getMoneyFormat()} ₫</span></span>
                                        </Row>
                                        <Link to={"/payment"}>
                                            <Button className="w-100 py-2 border-radius-none" color="warning">Thanh
                                                                                                              toán</Button>
                                        </Link>
                                    </Col>
                                </Row>
                            </>
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        cartData: state.cartData.cart,
    };
}

export default connect(mapStateToProps, {removeFromCart})(Cart);
