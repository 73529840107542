import React, {Component} from 'react';

import {
    Row, Col
} from "reactstrap";

import CartItem from "../../Cart/CartItem";
import {ModalImage} from "../../../components/Vietluck/ModalVietluck";

import LoadingPage from "../../../components/LoadingPage";

import apiMyAccount from "../../../api/MyAccount";

import utils from "../../../utils";

class ShoppingDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            isOpen_imageTicket: false
        }
    }

    componentDidMount() {
        let search = window.location.search
            .slice(1)
            .split('&')
            .map(p => p.split('='))
            .reduce((obj, [key, value]) => ({...obj, [key]: value}), {});
        this.ticketId = search.id;
        this.isOrder = search.isOrder;
        if (!utils.isEmpty(this.ticketId)) {
            if (this.isOrder) {
                apiMyAccount.getShopHistoryById(this.ticketId, (err, result) => {
                    if(err) {

                    } else {
                        this.setState({
                            ...result,
                            isLoaded: true
                        })
                    }
                })
            } else {
                apiMyAccount.getTicketById(this.ticketId, (err, result) => {
                    if (err) {

                    } else {
                        let data = {
                            id: result.id,
                            createdDate: result.createdDate,
                            ticketArray: [result],
                        };
                        this.setState({
                            ...data, isLoaded: true,
                        });
                    }
                })
            }

        } else {
            window.location.replace("/shopping-history");
        }
    }

    handlePressImage(res) {
        this.setState({
            imageTicket: res.image,
            imageTitle: res.title,
        }, () => this.toggle("isOpen_imageTicket"));

    }

    toggle(component){
        this.setState({
            [component]: !this.state[component]
        });
    }

    render() {

        if (!this.state.isLoaded) {
            return <LoadingPage/>
        }

        return (
            <div className="bootstrap-reset">
                <ModalImage
                    isOpen={this.state.isOpen_imageTicket}
                    toggle={this.toggle.bind(this, "isOpen_imageTicket")}
                    title={this.state.imageTitle}
                    image={this.state.imageTicket}
                />
                <Row className="p-2">
                    <Col>
                        <Row>
                            <span className="font-weight-bold">{this.isOrder ? "Đơn hàng" : "Vé"}:&nbsp;</span>
                            <span>#{this.state.id}</span>
                        </Row>
                        <Row>
                            <span className="font-weight-bold">Ngày mua:&nbsp;</span>
                            <span>{this.state.createdDate}</span>
                        </Row>
                        {
                            this.isOrder &&
                            <>
                                <Row>
                                    <span className="font-weight-bold">Trạng thái:&nbsp;</span>
                                    <span>{['Trong giỏ hàng',
                                        'Đã đặt mua',
                                        'Đang xử lý',
                                        'Đã bị hủy',
                                        'Hoàn thành'][this.state.statusId]}</span>
                                </Row>
                                <Row>
                                    <span className="font-weight-bold">Hình thức:&nbsp;</span>
                                    <span>{['Ship thường', 'Ship nhanh', 'Giữ hộ'][this.state.shipment.type]}</span>
                                </Row>
                            </>
                        }
                        <Row className="justify-content-center">
                            {
                                this.state.ticketArray.map((data, index) => (
                                    <div className="w-100">
                                        {/*{*/}
                                        {/*    data.statusId == 3 &&*/}
                                        {/*    <Text style={styles.textReject}>Đã bị hủy</Text>*/}
                                        {/*}*/}
                                        <CartItem
                                            // navigation={this.props.navigation}
                                            data={data}
                                            noTrash={true}
                                            renderImageTicket={data.statusId === 4}
                                            onPressImage={this.handlePressImage.bind(this)}
                                        />
                                    </div>
                                ))
                            }
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default ShoppingDetail;
