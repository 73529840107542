import React, { Component } from 'react';

import {
    Row, Col, Spinner
} from "reactstrap";

import { Link } from "react-router-dom";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import LoadingPage from "../../components/LoadingPage";

import apiMyAccount from "../../api/MyAccount";
import moment from "moment";

import ListItem from "../../components/Vietluck/ListItem";


import utils from "../../utils";

/* eslint eqeqeq: "off" */

const images = {
    vnpay: require('../../assets/images/icon/vnpay.png'),
    exchange: require('../../assets/images/icon/exchange.png'),
    atm: require('../../assets/images/icon/atm.png'),
    winner: require('../../assets/images/icon/winner.png'),
    lottery: require('../../assets/images/icon/lottery.png'),
    momo: require('../../assets/images/icon/momo.png'),
    vietluck: require("../../assets/images/logo/logo.png"),
};

function searchToObject() {
    return window.location.search
        .slice(1)
        .split('&')
        .map(p => p.split('='))
        .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});
}

// function convertHashPage(hash) {
//     try {
//         hash = hash.replace(/#/g, '');
//         hash = parseInt(hash);
//         if (!Number.isNaN(hash)) {
//             return hash;
//         }
//         return 1;
//     } catch (e) {
//         return 1;
//     }
// }

class PaymentHistory extends Component {

    constructor(props) {
        super(props);
        this.choice = searchToObject().status === "0" ? 0 : searchToObject().status === "2" ? 2 : 1;
        // this.page = convertHashPage(window.location.hash);
        this.page = 1;
        this.source = {
            '3': images.vnpay,//vnpay: "VNPAY",
            '2': images.exchange,//staff: "Nạp tại cửa hàng",
            '6': images.winner,//winPrize: "Trúng thưởng",
            '1': images.lottery,//order: "Mua hàng",
            '4': images.atm,//customerRequest: "Chuyển khoản"
            '5': images.exchange,
            '7':images.lottery,
            '8': images.atm,
            '9': images.atm,
            '10': images.momo,
            '11': images.momo,
            '13': images.vietluck,
            '41':images.momo
        };
        this.fluctuationTypeId = {
            '1': 'Mua vé',
            '2': 'Rút tiền tại quầy',
            '3': 'VNPAY',
            '4': 'Nạp chuyển khoản',
            '5': 'Nạp tại cửa hàng',
            '6': 'Trúng thưởng',
            '7':'Hoàn vé',
            '8': 'Yêu cầu nạp tiền',
            '9': 'Rút tiền về tài khoản ngân hàng',
            '10': 'Rút tiền về ví Momo',
            '11': "Momo",
            '13': "Rút về ví tài khoản",
            '41':"Nạp tiến Momo"
        };
        this.state = {
            tradeHistorySectionData: [],
            isLoading: false,
            isLoading_next: false,
            isLoading_prev: false,
            isLast: false
        }
    }

    componentDidMount() {
        this.handleRefresh("isLoading");
        document.querySelector(".content").addEventListener("scroll", (e) => {
            if(e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 5) {
                if(this.state.isLoading_next || this.state.isLast) {
                    return;
                } else {
                    this.handleNextPage();
                }
            }
            // console.log(e.target.scrollTop + " " + e.target.clientHeight + " " + e.target.scrollHeight)
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        // if (nextProps.history.action === "POP") {
        //     nextProps.history.replace("/", null);
        // }
    }

    componentWillUnmount() {
        document.querySelector(".content").removeEventListener("scroll", () => {});
    }

    sectionConvert(rawData) {
        try {
            let result = [];

            rawData.forEach(dt => {
                if (dt.fluctuationTypeId != 1) {
                    // dt.source = dt.source.sourceType;
                    dt.groupDate = moment(dt.createdDate, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY');
                    dt.createdDate = moment(dt.createdDate, 'DD-MM-YYYY HH:mm:ss').valueOf();
                    result.push(dt);
                }
            });

            result = result.sortBy('createdDate');
            for (let i = 0; i < result.length; i++) {
                result[i].createdDate = moment(result[i].createdDate).format('DD-MM-YYYY HH:mm:ss');
            }
            result.reverse();
            let data = [];
            let groupDate = result.groupBy(dt => dt.groupDate);
            groupDate.forEach(gr => {
                data.push({
                    groupDate: gr[0].groupDate,
                    data: gr,
                });
            });
            return data;
        } catch (e) {
            return [];
        }
    }

    handleRefresh(options) {
        if (this.state.isLoading || this.state.isLoading_next || this.state.isLoading_prev) {
            return;
        }

        this.setState({ [options]: true });
        apiMyAccount.getTransactionInfo(this.page, this.choice, (err, result) => {
            if (err) {
                this.setState({ [options]: false });
            } else {
                this.setState({ [options]: false });
                if(this.page===1 && result==null){
                    this.setState({
                        tradeHistorySectionData: [],   
                        isLast: true                   
                    });
                    return;
                }
                if (result === null) {
                    this.setState({isLast: true});
                    return;
                }
               
                let oldData = utils.copyState(this.state.tradeHistorySectionData);
                let newData = utils.copyState(result);
                if (oldData.length) {
                    if (oldData[oldData.length - 1].groupDate === newData[0].groupDate) {
                        oldData[oldData.length - 1].data = oldData[oldData.length - 1].data.concat(utils.copyState(newData[0].data));
                        newData.splice(0, 1);
                    }
                }

                newData = oldData.concat(newData);

                if (options === 'isLoading_next') {
                    this.setState({
                        tradeHistorySectionData: newData,
                        isLast: false,
                    });
                } else {
                    this.setState({
                        tradeHistorySectionData: result,
                        isLast: false,
                    });
                }
                // if (result === null) {
                //     this.setState({
                //         tradeHistorySectionData: [],
                //         isLast: true
                //     }, () => {
                //         $(".content").animate({ scrollTop: 0 }, 500);
                //     });
                //     return;
                // }
                // this.setState({
                //     tradeHistorySectionData: result,
                //     isLast: false
                // }, () => {
                //     $(".content").animate({ scrollTop: 0 }, 500);
                // });
            }
        });
    };

    handleSelectTab(tabIndex) {
        tabIndex = parseInt(tabIndex);
        this.choice = tabIndex;
        this.page = 1;
        this.props.history.push(`/payment-history?status=${this.choice}#1`);
        this.handleRefresh("isLoading");
    }

    handleNextPage() {
        this.page = this.page + 1;
        // window.location.hash = this.page;
        // this.props.history.push({
        //     pathname: "/payment-history",
        //     search: this.props.history.location.search,
        //     hash: "#" + this.page
        // });
        this.handleRefresh("isLoading_next");

    }

    // handlePrevPage() {
    //     this.page = this.page - 1;
    //     // window.location.hash = this.page;
    //     this.props.history.push({
    //         pathname: "/payment-history",
    //         search: this.props.history.location.search,
    //         hash: "#" + this.page
    //     });
    //     this.handleRefresh("isLoading_prev");
    // }

    renderItem() {
        if (this.state.tradeHistorySectionData.length === 0) {
            return (
                <span>Không có dữ liệu</span>
            )
        }
        return (
            this.state.tradeHistorySectionData.map(({ groupDate, data }, index) => (
                <Col key={index}>
                    <Row className="bg-primary p-1">{groupDate}</Row>
                    <Row>
                        <Col>
                            {
                                data.map(({ id, createdDate, amount, fluctuationTypeId, status }) => (
                                    <Link
                                        key={id}
                                        to={`/payment-history/detail?id=${id}&fluctuationTypeId=${fluctuationTypeId}`}
                                        className="link-unset"
                                    >
                                        <ListItem
                                            avatar={this.source[fluctuationTypeId.toString()]}
                                            title={this.fluctuationTypeId[fluctuationTypeId.toString()]}
                                            subtitle={(['1', '2', '9', '10'].includes(fluctuationTypeId) ? '- ' : '+ ') + (amount * 1000).getMoneyFormat() + ' ₫'}
                                            rightTitle={createdDate.split(' ')[1]}
                                        />
                                    </Link>
                                ))
                            }
                        </Col>
                    </Row>
                </Col>
            ))
        )
    }

    render() {
        if (this.state.isLoading) {
            return <LoadingPage />
        }
        return (
            <div className="payment-history-page">
                <Tabs selectedIndex={this.choice} onSelect={this.handleSelectTab.bind(this)}>
                    <TabList>
                        <Tab>Đang xử lý</Tab>
                        <Tab>Đã thành công</Tab>
                        <Tab>Đã hủy</Tab>
                    </TabList>

                    <TabPanel>
                        {this.renderItem()}
                    </TabPanel>
                    <TabPanel>
                        {this.renderItem()}
                    </TabPanel>
                    <TabPanel>
                        {this.renderItem()}
                    </TabPanel>
                    {
                        (this.state.isLoading_next && !this.state.isLast) &&
                        <Row className="bootstrap-reset m-0 p-0 bottom-loading d-flex justify-content-center align-items-center">
                        <Spinner
                            color="info"
                        />
                        {/* <Col className="text-center p-1">
                            <ButtonLoading
                                title={"Trang trước"}
                                disabled={this.page <= 1}
                                className="w-100"
                                loading={this.state.isLoading_prev}
                                onClick={this.handlePrevPage.bind(this)}
                            />
                        </Col>
                        <Col className="text-center p-1">
                            <ButtonLoading
                                title={"Trang sau"}
                                disabled={this.state.isLast}
                                className="w-100"
                                loading={this.state.isLoading_next}
                                onClick={this.handleNextPage.bind(this)}
                            />
                        </Col> */}
                    </Row>
                    }
                    
                </Tabs>
            </div>
        );
    }
}

export default PaymentHistory;
