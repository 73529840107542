import React, {Component} from 'react';

import {
    Col, FormGroup, Input, Label,
    Button,
    Modal, ModalHeader, ModalBody
} from "reactstrap";

import {connect} from "react-redux";
import {userChange} from "../../redux/actions/userAction";

import ButtonLoading from "../../components/FworkTag/ButtonLoading";
import LoadingPage from "../../components/LoadingPage";

import apiAddress from "../../api/Address";
import apiMyAccount from "../../api/MyAccount";

import utils from "../../utils";
import ValidInput from "../../utils/ValidInput";

class ModalChangePassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
            isLoading: false,
        }
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    save () {
        if (ValidInput.testPassword(this.state.currentPassword)) {
            return;
        }
        if (ValidInput.testPassword(this.state.newPassword)) {
            return;
        }
        if (ValidInput.testPassword(this.state.confirmPassword)) {
            return;
        }

        if (this.state.newPassword !== this.state.confirmPassword) {
            utils.alert("Xác nhận mật khẩu không chính xác")
            return;
        }

        this.setState({isLoading: true});

        apiMyAccount.changePassword({
            current_password: this.state.currentPassword,
            password: this.state.newPassword
        }, (err, result) => {
            if (err) {
                this.setState({isLoading: false});
            } else {
                this.setState({isLoading: false});
                utils.confirm("Thay đổi mật khẩu thành công!\r\nVui lòng đăng nhập lại!", (check) => {
                    window.location.replace("/auth/sign-in")
                });
                localStorage.clear()
            }
        })
    }

    render() {
        return (
            <Modal isOpen={this.props.isOpen} centered>
                <ModalHeader toggle={this.props.toggle}>Đổi mật khẩu</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label>Mật khẩu hiện tại</Label>
                        <Input
                            type={"password"}
                            name="currentPassword"
                            value={this.state.currentPassword}
                            onChange={this.handleChange.bind(this)}
                            autoComplete="off"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Mật khẩu mới</Label>
                        <Input
                            type={"password"}
                            name="newPassword"
                            value={this.state.newPassword}
                            onChange={this.handleChange.bind(this)}
                            autoComplete="off"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Xác nhận mật khẩu</Label>
                        <Input
                            type={"password"}
                            name="confirmPassword"
                            value={this.state.confirmPassword}
                            onChange={this.handleChange.bind(this)}
                            autoComplete="off"
                        />
                    </FormGroup>
                    <ButtonLoading
                        className="w-100"
                        title="Thay đổi"
                        loading={this.state.isLoading}
                        onClick={this.save.bind(this)}
                    />
                </ModalBody>
            </Modal>
        )
    }
}

class Profile extends Component {
    constructor(props) {
        super(props);
        this.city = [];
        this.district = [];
        this.state = {
            ...this.props.user,
            isOpen_changePassword: false,
            isLoaded: false
        }
    }

    componentDidMount() {
        apiAddress.getListCity((err, result) => {
            if (err) {

            } else {
                this.city = result;
                this.toggle("re_render");
            }
        });

        if (!utils.isEmpty(this.state.cityId)) {
            apiAddress.getListDistrictByCity(this.state.cityId, (err, result) => {
                if (err) {

                } else {
                    this.district = result;
                    this.toggle("re_render")
                }
            })
        }

        apiMyAccount.getUserInfoSomeKey((err, result) => {
            if(err) {

            } else {
                this.props.userChange(result);
                this.setState({isLoaded: true})
            }
        })
    }

    toggle(component) {
        this.setState({
            [component]: !this.state[component]
        });
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleCity(event) {
        let city = event.target.value;
        if (city !== this.state.cityId) {
            this.setState({cityId: city, districtId: ""});
            apiAddress.getListDistrictByCity(city, (err, result) => {
                if (err) {
                    // TODO
                } else {
                    this.district = result;
                    this.toggle("re_render");
                }
            });
        }
    };

    save = () => {
        if (ValidInput.testFullName(this.state.fullName)) {
            return;
        }

        if (ValidInput.testPhoneNumber(this.state.phoneNumber)) {
            return;
        }

        if (ValidInput.testIdCardNumber(this.state.idCardNumber)) {
            return;
        }

        const fields = ["fullName", "phoneNumber", "email", "address", "cityId", "districtId", "idCardNumber", "gender"];
        const data = {};
        fields.forEach(field => data[field] = this.state[field]);
        data.id = this.props.user.id;
        this.setState({isLoading: true});
        apiMyAccount.editUserInfo(data, (err, result) => {
            if (err) {
                this.setState({isLoading: false});
            } else {
                this.setState({isLoading: false});
                let user = utils.copyState(this.props.user);
                fields.forEach(field => user[field] = result[field]);
                this.props.userChange(user);
                utils.alert("Thay đổi của bạn đã được lưu lại!");
            }
        })

    };

    render() {
        if(!this.state.isLoaded) {
            return <LoadingPage/>
        }
        return (
            <div className="profile-page">
                <ModalChangePassword
                    isOpen={this.state.isOpen_changePassword}
                    toggle={this.toggle.bind(this, "isOpen_changePassword")}
                />
                <div className="profile-content">
                    <Col className="p-3">
                        <FormGroup>
                            <Label>Họ và tên</Label>
                            <Input
                                type="text"
                                name="fullName"
                                value={this.state.fullName}
                                placeholder={"Họ và tên"}
                                onChange={this.handleChange.bind(this)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Số điện thoại</Label>
                            <Input readOnly type="numeric" name="phoneNumber" value={this.state.phoneNumber} placeholder="Số điện thoại"/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Số CMND/CCCD</Label>
                            <Input
                                type="numeric"
                                name="idCardNumber"
                                value={this.state.idCardNumber}
                                onChange={this.handleChange.bind(this)}
                                placeholder="Số CMND /CCCD"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Giới tính</Label>
                            <Input
                                type="select"
                                name="gender"
                                value={this.state.gender}
                                onChange={this.handleChange.bind(this)}
                            >
                                <option value="M">Nam</option>
                                <option value="F">Nữ</option>
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>Email</Label>
                            <Input
                                type="email"
                                name="email"
                                value={this.state.email}
                                onChange={this.handleChange.bind(this)}
                                placeholder="Email"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Tỉnh / TP</Label>
                            <Input
                                type="select"
                                name="cityId"
                                value={this.state.cityId}
                                onChange={this.handleCity.bind(this)}
                            >
                                {
                                    this.city.map(({name, value}) => (
                                        <option key={value} value={value}>{name}</option>
                                    ))
                                }
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>Quận / Huyện</Label>
                            <Input
                                type="select"
                                name="districtId"
                                value={this.state.districtId}
                                onChange={this.handleChange.bind(this)}
                            >
                                {
                                    this.district.map(({name, value}) => (
                                        <option key={value} value={value}>{name}</option>
                                    ))
                                }
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>Địa chỉ</Label>
                            <Input
                                type="text"
                                name="address"
                                value={this.state.address}
                                onChange={this.handleChange.bind(this)}
                                placeholder="Địa chỉ"
                            />
                        </FormGroup>
                        <Button onClick={this.toggle.bind(this, "isOpen_changePassword")}>Đổi mật khẩu</Button>
                    </Col>
                </div>
                <div className="profile-footer">
                    <ButtonLoading
                        title={"Lưu thay đổi"}
                        color="warning"
                        className="border-radius-none py-2 w-100"
                        loading={this.state.isLoading}
                        onClick={this.save.bind(this)}
                    />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps, {userChange})(Profile);
