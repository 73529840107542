import React, { Component } from 'react';

import apiMyAccount from "../../api/MyAccount";
import { Row, Col } from "reactstrap";

import { Link } from "react-router-dom";

import LoadingPage from "../../components/LoadingPage";
import ButtonLoading from "../../components/FworkTag/ButtonLoading";

import utils from "../../utils";
/* eslint-disable */

const images = {
    mega: require('../../assets/images/icon/mega645.png'),
    power: require('../../assets/images/icon/power655.png'),
    keno: require('../../assets/images/icon/keno.png'),
    max4d: require('../../assets/images/icon/max4d.png'),
    max3d: require('../../assets/images/icon/max3d.png'),
    vietluck: require('../../assets/images/logo/logo.png'),
    vnpay: require('../../assets/images/icon/vnpay.png'),
    exchange: require('../../assets/images/icon/exchange.png'),
    atm: require('../../assets/images/icon/atm.png'),
    winner: require('../../assets/images/icon/winner.png'),
    lottery: require('../../assets/images/icon/lottery.png'),
    momo: require("../../assets/images/icon/momo.png"),
    logo:require("../../assets/images/logo/logo.png"),
    dientoan:require("../../assets/images/logo/dien-toan.png")
};
const lottery_type = ["", "mega", "power", "max4d", "max3d", "max3d", "keno","dientoan","dientoan","dientoan","dientoan","dientoan","dientoan"];
const notifyType = [
    "Thông báo chung hệ thống(ver2 phát triển)",
    "Nạp tiền", // 5
    "Đơn hàng",
    "Kết quả mới",
    "Trúng thưởng",
    "Mua vé",
    "Thông báo chung hệ thống(ver2 phát triển)",
    "Rút tiền"
];


class NotificationItem extends React.Component {

    navigateTo(data) {
        let notify = {};
        switch (data.notifyType) {
            case 6: {
                return "#";
            }
            case 1:
                if (utils.isEmpty(data.data.id)) {
                    utils.alert("Không có dữ liệu");
                    return;
                }
                notify.notify = {
                    id: data.data.id,
                    type: data.data.type == 4 ? data.data.status == 4 ? "id" : "requestId" : "id"
                };
                // this.props.navigation.navigate('PaymentDetail', notify);
                return `/payment-history/detail?id=${notify.notify.id}&fluctuationTypeId=${notify.notify.type === "requestId" ? 2 : 1}`;
            case 7:
                if (utils.isEmpty(data.data.id)) {
                    utils.alert("Không có dữ liệu");
                    return;
                }
                notify.notify = {
                    id: data.data.id,
                    type: (![2, 9, 10].includes(data.data.type)) ? "id" : "requestId"
                    // type: (![2, 4, 9, 10].includes(data.data.type)) ? "id" : "requestId"
                    // 12/02/2020 - Chuyển loại 4: Nạp tiền qua ngân hàng requestId -> Id
                };

                // this.props.navigation.navigate('PaymentDetail', notify);
                return `/payment-history/detail?id=${notify.notify.id}&fluctuationTypeId=${notify.notify.type === "requestId" ? 2 : 1}`;
            case 2:
                notify = {
                    ticketId: data.data.id,
                    isOrder: true,
                    status: data.data.status,
                    notify: true
                };
                // this.props.navigation.navigate('ShoppingDetail', notify);
                return `/shopping-history/detail?id=${notify.ticketId}&isOrder=true`;
            case 5:
                notify = {
                    ticketId: data.data.id,
                    notify: true
                };
                // this.props.navigation.navigate('ShoppingDetail', notify);
                return `/shopping-history/detail?id=${notify.ticketId}`;
            case 3: {

                // switch (parseInt(data.data.produceId)) {
                //     case 1:
                //         // this.props.navigation.navigate("ResultMega645");
                //         return "/result#"
                //     case 2:
                //         this.props.navigation.navigate("ResultPower655");
                //         break;
                //     case 3:
                //         this.props.navigation.navigate("ResultMax4D");
                //         break;
                //     case 4:
                //         this.props.navigation.navigate("ResultMax3D");
                //         break;
                //     case 6:
                //         this.props.navigation.navigate("ResultKeno");
                //         break;
                // }
                return "/result"
            }
            case 4: {
                notify = {
                    ticketId: data.data.ticketId,
                    winner: true,
                    notify: true,
                    value: data.data.value,
                    term: data.data.term
                };
                // this.props.navigation.navigate('ShoppingDetail', notify);
                return `/shopping-history/detail?id=${notify.ticketId}`
            }
            default:
                break;
        }
    };

    getImage() {
        let dt = this.props.item;

        let image = null;

        switch (dt.notifyType) {
            case 1:
                switch (dt.data.type) {
                    case 5:
                        image = images["exchange"];
                        break;
                    case 3:
                        image = images["vnpay"];
                        break;
                    case 4:
                        image = images["atm"];
                        break;
                    case 11:
                    case 10:
                        image = images["momo"];
                        break;
                    
                    default:
                        break;
                }
                break;
            case 2:
            case 5:
                image = images["lottery"];
                break;
            case 3:
            case 4:
                image = images[lottery_type[parseInt(dt.data.produceId)]];
                break;
            case 7:
                switch (dt.data.type) {
                    case 2:
                        image = images["exchange"];
                        break;
                    case 9:
                        image = images["atm"];
                        break;
                    case 11:
                    case 10:
                        image = images["momo"];
                        break;
                    default:
                        break;
                }
                break;
            case 6:
                image=images['logo'];
                break;
            default:
                break;
        }

        return image;
    }

    getContent() {
        let dt = this.props.item;
        let content = "";
        switch (dt.notifyType) {
            case 1:
                content = `Yêu cầu nạp tiền #${dt.data.id} - ${["", "", "Đang xử lý", "Đã bị hủy", "Thành công"][dt.data.status]}.`;
                if (dt.data.status == 3) {
                    content += ` Lý do: ${dt.data.reason}`;
                }
                break;
            case 2:
                content = `Đơn hàng #${dt.data.id} - ${["", "", "Đang xử lý", "Đã bị hủy", "Đã hoàn thành"][dt.data.status]}`;
                break;
            case 5:
                content = `Vé #${dt.data.id} - ${["", "", "Đang xử lý", "Đã bị hủy", "Đã hoàn thành"][dt.data.status]}`;
                break;
            case 3:
                content = `Đã có kết quả mới #kỳ ${dt.data.term} ngày #${dt.data.date} ${dt.data.result}`;
                break;
            case 4:
                content = `Quý khách đã trúng thưởng vé #${dt.data.ticketId} - kỳ quay số mở thưởng #${dt.data.term}. Tổng giá trị giải thưởng ${(dt.data.value * 1000).getMoneyFormat()} đ`;
                break;
            case 6:
                content = dt.data.content;
                break;
            case 7:
                content = `Yêu cầu rút tiền #${dt.data.id} - ${["", "", "Đang xử lý", "Đã bị hủy", "Thành công"][dt.data.status]}.`;
                if (dt.data.status == 3) {
                    content += ` Lý do: ${dt.data.reason}`;
                }
                break;
            default:
                break;
        }
        return content;
    }

    getTitle=()=>{
        if(this.props.item.notifyType==6)
        return this.props.item.data.title;
        else return notifyType[this.props.item.notifyType];
    }
    render() {
        return (
            <Row className="notification-item bootstrap-reset p-0 m-0 border-bottom p-1">
                <Col>
                    <Link to={this.navigateTo(this.props.item)} className="link-unset">
                        <Row>
                            <div className="left-component">
                                <img src={this.getImage()} alt={"img"} />
                            </div>
                            <div className="content-component">
                                <Row>
                                    <span className="font-weight-bold">{this.getTitle()}</span>
                                </Row>
                                <Row>
                                    <span>{this.getContent()}</span>
                                </Row>
                                <Row>
                                    <span>{this.props.item.time}</span>
                                </Row>
                            </div>
                        </Row>
                    </Link>
                </Col>
            </Row>
        )
    }
}

class Notifications extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            isLoading: false,
            page: 1,
            isLast: false
        }
    }

    componentDidMount() {
        apiMyAccount.setReadAll();
        apiMyAccount.getNotify(this.state.page, (err, result) => {
            if (err) {

            } else {
                this.setState({
                    data: result,
                    isLoaded: true,
                    page: 2,
                    isLast:!result
                })
            }
        })
    }

    handleLoadMore() {
        if (this.state.isLoading) return;
        this.setState({ isLoading: true });
        apiMyAccount.getNotify(this.state.page, (err, result) => {
            if (err) {

            } else {
                if (result === null) {
                    this.setState({ isLast: true });
                    return;
                }
                let data = JSON.parse(JSON.stringify(this.state.data));
                data = data.concat(result);
                this.setState({
                    data: data,
                    isLoaded: true,
                    isLoading: false,
                    page: this.state.page + 1
                })
            }
        })
    }
    renderNotifi=()=>{
        if(this.state.data==null){
            
            return null;
    }
        else 
        return this.state.data.map(({ groupDate, data }, index) => (
            <div key={index}>
                <div className="bg-primary px-2">{groupDate}</div>
                <div>
                    {
                        data.map((item, index2) => (
                            <NotificationItem
                                key={`notify-${index2}`}
                                item={item}
                            />
                        ))
                    }
                </div>
            </div>
        ))
    }
    render() {
        if (!this.state.isLoaded) {
            return (
                <LoadingPage />
            )
        }
        return (
            <div className="bootstrap-reset">
                {
                    this.renderNotifi()
                    
                }
                {
                    !this.state.isLast && <Row>
                        <ButtonLoading
                            loading={this.state.isLoading}
                            title="Xem thêm"
                            className="w-100 border-radius-none"
                            onClick={this.handleLoadMore.bind(this)}
                        />
                    </Row>
                }
            </div>
        );
    }
}

export default Notifications;
