import React, {Component} from 'react';

import {
    Row, Col
} from "reactstrap";

import {connect} from "react-redux";
import {lotteryChange} from "../../redux/actions/lotterySelectorAction";
import utils from "../../utils";

import ModalSelector from "./ModalSelector";
import NumberCircle from "./NumberCircle";
import {ModalSelect} from "./ModalVietluck";

import {
    RefreshCcw, Trash
} from "react-feather";
/* eslint-disable */
const productDataset = require('../../utils/productData').productDataset;

export function checkValidValue(value, productId, productOptionId) {
    if (productId === 3) {
        if ([42, 45].includes(productOptionId)) {
            return !(value[0] === value[1] && value[1] === value[2] && value[2] === value[3]);
        }
    }
    return true;
}

export function getRandomValue(productId, productOptionId) {
    if (productOptionId !== 82) {
        let lengthValue = 2;
        if (productId === 4 || productId === 5 || productId === 12) {
            lengthValue = 3;
        }
        if (productId === 3) {
            switch (productOptionId) {
                case 43:
                case 44:
                    lengthValue = 3;
                    break;
                default:
                    lengthValue = 4;
                    break;
            }
        }
        if(productId===7 || productId===10 || productId===11){
            lengthValue=1;
        }
        let productData = productDataset.find(dt => dt.id == productId);
        let productOption = productData.productOptionArray.find(dt => dt.id == productOptionId);
        let number = Math.floor(Math.random() * productOption.endNumber) + 1;
        number = utils.formatValue(number, lengthValue);
        while (!checkValidValue(number, productId, productOptionId)) {
            number = Math.floor(Math.random() * productOption.endNumber) + 1;
            number = utils.formatValue(number, lengthValue);
        }

        return number;
    } else { // Keno chẵn lẻ - lớn nhỏ
        let num = (Math.floor(Math.random() * 4) + 1).toString()
        return num;
    }
}

class LotterySelector extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen_selectPrice: false,
            isOpen: false,
            indexSelector: 0,

        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.isRandom === true) {
            let value = utils.copyState(this.props.lotterySelector.value);
            for (let i = 0; i < this.getNumTicket(); i++) {
                value[i].number = this.handleRandom(i, true);
            }
            this.props.lotteryChange({value: value});
            this.props.doneRandom();
        }
        console.log(nextProps)
        if (nextProps.isTCAll === true) {
            if( this.props.lotterySelector.term.length>1){
                utils.alert("Vietluck hiện chưa hỗ trợ tính năng chọn nhiều kỳ cho vé TC!");
                this.props.doneTCAll();
                return;
           }
            const value = utils.copyState(this.props.lotterySelector.value);
            for (let i = 0; i < this.getNumTicket(); i++) {
                value[i].number = Array(this.props.lotterySelector.numberSelect).fill('TC');
                value[i].isTC=1
            }
            this.props.lotteryChange({value: value});
            this.props.doneTCAll();
        }
    }

    handleRandom(id, options) {
        let changeValue = [];
        for (let i = 0; i < this.props.lotterySelector.numberSelect; i++) {
            while (true) {
                let number = getRandomValue(this.props.lotterySelector.productId, this.props.lotterySelector.productOptionId);
                if (!changeValue.includes(number)) {
                    changeValue.push(number);
                    break;
                }
            }
        }
        if(this.props.lotterySelector.productId<7)
        changeValue.sort();
        if (options) {
            return changeValue;
        }
        let value = utils.copyState(this.props.lotterySelector.value);
        value[id].number = changeValue;
        this.props.lotteryChange({value: value});
    }

    handleRemove(id) {
        let value = utils.copyState(this.props.lotterySelector.value);
        value[id].number = [];
        value[id].isTC = 0;
        this.props.lotteryChange({value: value});
    }

    handleChangePrice(price) {
        let value = utils.copyState(this.props.lotterySelector.value);
        value[this.state.indexSelector].price = parseInt(price);
        this.props.lotteryChange({value: value});
        this.toggle('isOpen_selectPrice');
    }
    handleSelectTC=(index)=>{
        
        if( this.props.lotterySelector.term.length>1){
            utils.alert("Vietluck hiện chưa hỗ trợ tính năng chọn nhiều kỳ cho vé TC!");
             return;
        }
         const value = utils.copyState(this.props.lotterySelector.value);
         value[index].number = Array(this.props.lotterySelector.numberSelect).fill('TC');
         value[index].isTC=1
         this.props.lotteryChange({value: value});
     }
    handleSelectNumberKeno2(rowIndex, number) {
        let value = utils.copyState(this.props.lotterySelector.value);
        value[rowIndex].number = [number.toString()];
        this.props.lotteryChange({value: value});
    }

    getNumTicket() {
        if(this.props.isKenoBao)
        return 1;
        return this.props.lotterySelector.productOptionId === 82 ? 4 : 6;
    }

    toggle(component) {
        this.setState({
            [component]: !this.state[component]
        });
    }

    renderKenoBao=()=>{
        const {productOptionKenoTypeId,productOptionId}=this.props.lotterySelector;
        if(this.props.isKenoBao && this.props.lotterySelector.value[0].number.length>0 && productOptionKenoTypeId>0){

        var genNum=this.genNumberKenoType(this.props.lotterySelector.value[0].number,productOptionId,productOptionKenoTypeId);
           
            

        return (<>
            <div>Bộ số được tạo({genNum.length})</div>
                <div style={{display:'flex',flexDirection:'row', flexWrap:'wrap', justifyContent:'space-around'}}>
                {
                   genNum.map(data=>(
                        <div style={{marginLeft:5, marginRight:5}}>{data.join(' ')}</div>
                    ))
                }</div>
              </>)
            
        }
    }
    genNumberKenoType(number, productOptionId, productOptionKenoTypeId) {
        if (number.length === 0) return [];
        let result = [];
        let arrCombination = utils.generateCombination(productOptionId - 70, productOptionKenoTypeId);

        arrCombination.forEach(combination => {
            let rs = [];
            for (let i = 1; i <= productOptionId - 70; i++) {
                rs.push(number[combination[i] - 1]);
            }
            result.push(rs);
        });
        return result;
    };
    render() {
        const numTicket = this.getNumTicket();
        return (
            <Col>
                <ModalSelector
                    isOpen={this.state.isOpen}
                    onClose={this.toggle.bind(this, "isOpen")}
                    index={this.state.indexSelector}
                />
                <ModalSelect
                    title="Chọn mệnh giá"
                    isOpen={this.state.isOpen_selectPrice}
                    toggle={this.toggle.bind(this, "isOpen_selectPrice")}
                    checked={this.props.lotterySelector.value[this.state.indexSelector].price}
                    data={this.props.lotterySelector.priceArray}
                    keyId={"id"}
                    keyLabel={"price"}
                    onChange={this.handleChangePrice.bind(this)}
                />
                {this.props.isKenoBao? <div>Chọn số:</div>:null}
                {

                    Array(numTicket).fill(0).map((row, rowIndex) => (
                        <Row className="w-100 align-items-center my-2" key={`rowIndex-${rowIndex}`}>
                            {this.props.isKenoBao?null: 
                            <div className="text-left" style={{width: "10%"}}>
                                <NumberCircle title={String.fromCharCode(rowIndex + 65)}/>
                            </div>
                            }
                           
                            <div
                                className="text-center"
                                onClick={() => {
                                    if(this.props.lotterySelector.productOptionId !== 82) {
                                        this.setState({indexSelector: rowIndex, isOpen: true})
                                    }
                                }}
                                style={{width: [3, 4, 5, 6,12].includes(this.props.lotterySelector.productId) ? (this.props.isKenoBao?"70%":"60%") : "80%"}}
                            >
                                {
                                    this.props.lotterySelector.productOptionId !== 82 ?
                                        <React.Fragment>
                                            {
                                                this.props.lotterySelector.value[rowIndex].number.map((number, i) => (
                                                    [3, 4, 5,12].includes(this.props.lotterySelector.productId)
                                                        ?
                                                        <NumberCircle
                                                            key={i.toString()}
                                                            price={number}
                                                            className="hover-pointer"
                                                        />
                                                        :
                                                        <NumberCircle
                                                            size={32}
                                                            key={i.toString()}
                                                            title={number}
                                                            className="mr-1 hover-pointer"
                                                        />
                                                ))
                                            }
                                            {
                                                Array(this.props.lotterySelector.numberSelect - this.props.lotterySelector.value[rowIndex].number.length).fill('').map((l, i) => (
                                                    [3, 4, 5,12].includes(this.props.lotterySelector.productId)
                                                        ?
                                                        <NumberCircle
                                                            key={i.toString()}
                                                            price={l}
                                                            className="hover-pointer"
                                                        />
                                                        :
                                                        <NumberCircle
                                                            key={i.toString()}
                                                            title={l}
                                                            className="mr-1 hover-pointer"
                                                        />
                                                ))
                                            }
                                        </React.Fragment>
                                        :
                                        [0,1].includes(rowIndex) ? (
                                        <React.Fragment>
                                            <div>
                                                <NumberCircle
                                                    price="Chẵn"
                                                    isBackground={this.props.lotterySelector.value[rowIndex].number[0] == 2}
                                                    onClick={this.handleSelectNumberKeno2.bind(this, rowIndex, 2)}
                                                    className="mr-1 hover-pointer"
                                                />
                                                <NumberCircle
                                                    price="Lẻ"
                                                    isBackground={this.props.lotterySelector.value[rowIndex].number[0] == 1}
                                                    onClick={this.handleSelectNumberKeno2.bind(this, rowIndex, 1)}
                                                    className="ml-1 hover-pointer"
                                                />
                                                <NumberCircle
                                                    price="Hòa CL"
                                                    isBackground={this.props.lotterySelector.value[rowIndex].number[0] == 5}
                                                    onClick={this.handleSelectNumberKeno2.bind(this, rowIndex, 5)}
                                                    className="ml-1 hover-pointer"
                                                />
                                            </div>
                                            <div>
                                                <NumberCircle
                                                    price="Lớn"
                                                    isBackground={this.props.lotterySelector.value[rowIndex].number[0] == 4}
                                                    onClick={this.handleSelectNumberKeno2.bind(this, rowIndex, 4)}
                                                    className="mr-1 hover-pointer"
                                                />
                                                <NumberCircle
                                                    price="Nhỏ"
                                                    isBackground={this.props.lotterySelector.value[rowIndex].number[0] == 3}
                                                    onClick={this.handleSelectNumberKeno2.bind(this, rowIndex, 3)}
                                                    className="ml-1 hover-pointer"
                                                />
                                                <NumberCircle
                                                    price="Hòa LN"
                                                    isBackground={this.props.lotterySelector.value[rowIndex].number[0] == 6}
                                                    onClick={this.handleSelectNumberKeno2.bind(this, rowIndex, 6)}
                                                    className="ml-1 hover-pointer"
                                                />
                                            </div>
                                        </React.Fragment>
                                    ): (
                                        <React.Fragment>
                                            <div>
                                                <NumberCircle
                                                    price="Chẵn 11-12"
                                                    isBackground={this.props.lotterySelector.value[rowIndex].number[0] == 7}
                                                    onClick={this.handleSelectNumberKeno2.bind(this, rowIndex, 7)}
                                                    className="mr-1 hover-pointer w90"
                                                />
                                                <NumberCircle
                                                    price="Lẻ 11-12"
                                                    isBackground={this.props.lotterySelector.value[rowIndex].number[0] == 8}
                                                    onClick={this.handleSelectNumberKeno2.bind(this, rowIndex, 8)}
                                                    className="ml-1 hover-pointer w80"
                                                />                                                
                                            </div>                                            
                                        </React.Fragment>
                                    )
                                }
                            </div>
                            <div className="text-right">
                                {
                                    this.props.lotterySelector.value[rowIndex].number.length !== 0 ?
                                        <NumberCircle
                                            icon={<Trash size={15}/>}
                                            onClick={this.handleRemove.bind(this, rowIndex)}
                                            className="hover-pointer"
                                        />
                                        :
                                        <NumberCircle
                                            icon={<RefreshCcw size={15}/>}
                                            onClick={this.handleRandom.bind(this, rowIndex, false)}
                                            className="hover-pointer"
                                        />
                                }
                                {
                                    [3, 4, 5, 6,12].includes(this.props.lotterySelector.productId) &&
                                    <NumberCircle
                                        price={this.props.lotterySelector.value[rowIndex].price.toString() + 'K'}
                                        className="ml-1 hover-pointer"
                                        onClick={() => {
                                            this.setState({indexSelector: rowIndex, isOpen_selectPrice: true})
                                        }}
                                    />
                                }
                            </div>
                        </Row>
                    ))
                }
                {
                    this.renderKenoBao()
                }
            </Col>
        );
    }
}

function mapStateToProps(state) {
    return {
        lotterySelector: state.lotterySelector,
    };
}

export default connect(mapStateToProps, {lotteryChange})(LotterySelector);
