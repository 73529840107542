import React, { Component } from 'react'
import LoadingPage from "../../components/LoadingPage";
import affiliateApi from '../../api/affiliate';
import { FormGroup, Input, Label, Row, Col, Button } from "reactstrap";
import utils from "../../utils";
import PushNotify from "../../components/Vietluck/PushNotify";
export class Affiliate extends Component {
    constructor(props) {
        super(props);
        this.state={
            isLoading:true,
            affiliateData:{}
        }
    }


    componentDidMount(){
        affiliateApi.getAffiliate((err,data)=>{
            if(err){
              
                this.setState({isLoading:false});
                return;
            }
            else{
                this.setState({affiliateData:data,isLoading:false});
            }
        });
    }
    handleCopy=()=>{
        let copyText = document.querySelector("#copy-code");
        copyText.focus();        
        copyText.select();
        document.execCommand("copy");
        PushNotify(1, "success", "Sao chép url", "Đã sao chép")
        
    }

    render() {
        if (this.state.isLoading) {
            return <LoadingPage />
        }
        return (
            <div className="bootstrap-reset p-2"> 
                <div className="bank-box">
                    <div className="font-weight-bold">
                        Mã chia sẻ của bạn:
                    </div>
                    <Row className="font-weight-bold text-danger justify-content-center" style={{fontSize:20}}>
                        {this.state.affiliateData.codeShare}
                    </Row>
                    <div className="text-center" >hoặc</div>
                    <div style={{flexDirection:'row',justifyContent:'center',display:'flex'}}>
                        <input value={"https://muavietlott.com/auth/sign-up?rel="+ this.state.affiliateData.codeShare} style={{flexGrow:1,marginRight:2}} readOnly id="copy-code"/>
                        <Button className="text-center" onClick={()=>this.handleCopy()} >Sao chép</Button>    

                    </div>
                    <Row className="font-weight-bold justify-content-center" >
                        Dùng mã trên đăng ký hệ thống để nhận hoa hồng trên mỗi đơn hàng.
                        <a href="#">Tìm hiểu thêm.</a>
                    </Row>

                </div>
                <div className="font-weight-bold mt-4 mb-1">
                    
                </div>
                <div  className="bank-box">                           
                    <Row className="mt-2">
                        <Col>
                            <Row>
                                <span className="font-weight-bold">Số người đã đăng ký:&nbsp;</span>
                                {this.state.affiliateData.registerCount}</Row>
                            <Row>
                                <span className="font-weight-bold">Tài khoản giới thiệu: </span>&nbsp;
                               <div>{ ( this.state.affiliateData.affiliateBalance*1000).getMoneyFormat()}đ</div>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mt-1 mb-2">                        
                            <Button className="w-100 text-center" onClick={()=>{
                               this.props.history.push("/thanh-toan-hoa-hong");
                            }} >Thanh toán</Button>                        
                    </Row>
                </div>
            </div>
        )
    }
}

export default Affiliate
