import React, { Component } from 'react'
import {
    Row,
    FormGroup, Input, Label,CustomInput
} from "reactstrap";
import LoadingPage from "../../components/LoadingPage";
import affiliateApi from '../../api/affiliate';
import InputCurrency from "../../components/Vietluck/InputCurrency";
import {Phone, Mail, Facebook,Users} from "react-feather";
import utils from "../../utils";
import ButtonLoading from "../../components/FworkTag/ButtonLoading";
export class Withdraw extends Component {
    constructor(props){
        super(props);
        this.state={
            //0:wallet
            //1:bank
            affiliateData:{},
            isLoading:true,
            destination:0,
            amount:0
        }
    }

    handleChangeMoney=(amount)=> {
        this.setState({ amount: amount })
    }

    componentDidMount(){
        affiliateApi.getAffiliate((err,data)=>{
            if(err){
                utils.alert(err);
                this.setState({isLoading:false});
                return;
            }
            else{
                this.setState({affiliateData:data,isLoading:false});
            }
        });
    }
    submit=()=>{
        if(this.state.affiliateData.affiliateBalance*1000<this.state.amount||this.state.affiliateData.affiliateBalance*1000===0){
            utils.alert("Số dư không đủ");
        return
    }
        
        if (this.state.amount <= 0 || this.state.amount % 100 !== 0) {
            utils.alert("Số tiền phải là bội của 100");
            return;
        }
        affiliateApi.deposit(this.state.amount,(err,data)=>{
            if(err){
               
                this.setState({isLoading:false});
                return;
            }
            else{
               utils.alert("Thành công");
               window.location.replace("/");
            }
        })
    }
    render() {
        if (this.state.isLoading) {
            return <LoadingPage />
        }
        return (
            <div  className="bootstrap-reset p-2">
                <div style={{flexDirection:'row',display:'flex', justifyContent:"space-between",paddingBottom:2,borderBottom:1,borderBottomColor:'#000',
                borderBottomStyle:'solid',marginBottom:5}}>
                    <div>                    
                        <Users
                            color={"red"}                            
                        />
                        <span className="font-weight-bold"> Hoa hồng:</span>
                    </div>
                    <span className="font-weight-bold" style={{color:'red'}} >{(this.state.affiliateData.affiliateBalance*1000).getMoneyFormat()}đ</span>
                </div>

                
                 <FormGroup>
                    <Label>Nhập Số tiền</Label>
                    <InputCurrency onChange={this.handleChangeMoney.bind(this)} />
                </FormGroup>
                <CustomInput
                    
                    type={"radio"}
                    checked={true}
                    label={"Nạp ví Vietluck"}
                    className="my-1"
                    name="price"                   
                />
                <Row className="mt-4">
                    <ButtonLoading    
                        onClick={()=>{this.submit()}}                    
                        className="w-100"
                        title="Rút tiền"                       
                    />
                </Row>
            </div>
        )
    }
}

export default Withdraw
