import React, {Component} from 'react';
import {Spinner} from "reactstrap";

class LoadingPage extends Component {
    render() {
        return (
            <div style={styles}>
                <Spinner color="info"/>
            </div>
        );
    }
}

export default LoadingPage;

const styles = {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
};
