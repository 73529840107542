import rootApi, { getToken } from "./rootApi";
import ErrorMessage from "../utils/ErrorMessage";

const utils = require('../utils');

const path = require('../config').path;

function checkCode(data,callback){
    rootApi.get(path.affiliate.check+"?code="+data)
        .then(res => {
            let result = res.data;            
            return callback(null, result);
        })
        .catch(error => {
            ErrorMessage(error);
            return callback(error);
        });
}
function getAffiliate(callback){
    rootApi.get(path.affiliate.get)
        .then(res => {
            let result = res.data;            
            return callback(null, result);
        })
        .catch(error => {
            ErrorMessage(error);
            return callback(error);
        });
}
function deposit(amount,callback){
    rootApi.post(path.affiliate.deposit,{amount})
        .then(res => {
            let result = res.data;            
            return callback(null, result);
        })
        .catch(error => {
            ErrorMessage(error);
            return callback(error);
        });
}
export default{checkCode,
    getAffiliate,
    deposit
}