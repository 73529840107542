// Auth pages
import SignIn from "../pages/Auth/SignIn";
import SignUp from "../pages/Auth/SignUp";
import OTP from "../pages/Auth/OTP";
import ForgotPassword from "../pages/Auth/ForgotPassword";

// Dashboard without sidebar
import LotteryMega645 from "../pages/Lottery/Mega645/LotteryMega645";
import LotteryPower655 from "../pages/Lottery/Power655/LotteryPower655";
import LotteryMax4D from "../pages/Lottery/Max4D/LotteryMax4D";
import LotteryMax3D from "../pages/Lottery/Max3D/LotteryMax3D";
import LotteryMax3D_Plus from "../pages/Lottery/Max3D_Plus/LotteryMax3D_Plus";
import LotteryMax3D_Pro from "../pages/Lottery/Max3DPro/Max3DPro";
import LotteryKeno from "../pages/Lottery/Keno/LotteryKeno";
import LotteryKenoBao from "../pages/Lottery/Keno/LotteryKenoBao";
import MainAnalytic from "../pages/Analytics/MainAnalytic";
import MainResult from "../pages/Results/MainResult";
import Profile from "../pages/Profile/ProfilePage";

import Term from "../pages/Term/Term";
import PaymentHistory from "../pages/PaymentHistory/PaymentHistory";
import PaymentDetail from "../pages/PaymentHistory/PaymentDetail/PaymentDetail";
import ShoppingHistory from "../pages/ShoppingHistory/ShoppingHistory";
import ShoppingDetail from "../pages/ShoppingHistory/ShoppingDetail/ShoppingDetail";
import CompareResult from "../pages/ShoppingHistory/CompareResult/CompareResult";
import Help from "../pages/Help/Help";

import Recharge from "../pages/Wallet/Recharge";
import RechargeItems from "../pages/Wallet/RechargeItems";
import Withdrawal from "../pages/Wallet/Withdrawal";
import WithdrawalItems from "../pages/Wallet/WithdrawalItems";

import VNPAY_Return from "../pages/Wallet/VNPAY_Check";
import Momo_Check from "../pages/Wallet/Momo_Check";

import Notifications from "../pages/Notifications/Notifications";

import Payment from "../pages/Payment/Payment";

import Cart from "../pages/Cart/Cart";

import Home from "../pages/Home/Home";
import Affiliate from "../pages/Affiliate/Affiliate";
import Withdraw from "../pages/Affiliate/Withdraw";
import KTMB from "../pages/KTMB/KTMB";
import DT235 from "../pages/Lottery/DT235/DT235";
import DT234 from "../pages/Lottery/DT234/DT234";
import DT6x36 from "../pages/Lottery/DT6x36/DT6x36";
import DTThantai from '../pages/Lottery/DTThantai/DTThantai'
import DT123 from '../pages/Lottery/DT123/DT123'
import Live from '../pages/truc-tiep-keno/index'
import {
    faHome, faStore, faMoneyBill, faChartLine, faUser, faPhone, faLock, faTrophy,faUsers
} from "@fortawesome/free-solid-svg-icons"

/* eslint eqeqeq: "off" */
 
const privacyRoutes = {
    path: "",
    name: "Privacy",
    children: [
        {
            path: "/thanh-toan-hoa-hong",
            name: "Deposit",
            component: Withdraw
        },
        {
            path: "/auth/sign-in",
            name: "SignIn",
            component: SignIn
        },
        {
            path: "/auth/sign-up",
            name: "SignUp",
            component: SignUp
        },
        {
            path: "/dien-toan",
            name: "Xổ số điện toán",
            component: KTMB,
            icon: faHome
        },
        {
            path: "/lottery/234",
            name: "DT234",
            component: DT234
        },
        {
            path: "/lottery/235",
            name: "DT235",
            component: DT235
        },
        {
            path: "/lottery/6x36",
            name: "DT6x36",
            component: DT6x36
        },
        {
            path: "/truc-tiep-keno",
            name: "Live",
            component: Live
        },
        {
            path: "/lottery/than-tai",
            name: "DTThantai",
            component: DTThantai
        },
        {
            path: "/lottery/123",
            name: "DT123",
            component: DT123
        },
        {
            path: "/lottery/mega645",
            name: "LotteryMega645",
            component: LotteryMega645
        },
        {
            path: "/lottery/power655",
            name: "LotteryPower655",
            component: LotteryPower655
        },
        {
            path: "/lottery/max4d",
            name: "LotteryMax4D",
            component: LotteryMax4D
        },
        {
            path: "/lottery/max3d",
            name: "LotteryMax3D",
            component: LotteryMax3D
        },
        {
            path: "/lottery/max3d_pro",
            name: "LotteryMax3D_Pro",
            component: LotteryMax3D_Pro
        },
        {
            path: "/lottery/max3d_plus",
            name: "LotteryMax3D_Plus",
            component: LotteryMax3D_Plus
        },
        {
            path: "/lottery/keno",
            name: "LotteryKeno",
            component: LotteryKeno
        },
        {
            path: "/lottery/kenobao",
            name: "LotteryKenoBao",
            component: LotteryKenoBao
        },
        {
            path: "/cart",
            name: "Cart",
            component: Cart
        },
        {
            path: "/payment",
            name: "Payment",
            component: Payment
        },
        {
            path: "/payment-history/detail",
            name: "Payment Detail",
            component: PaymentDetail
        },
        {
            path: "/shopping-history/detail",
            name: "Shopping Detail",
            component: ShoppingDetail
        },
        {
            path: "/compare-result",
            name: "Compare result",
            component: CompareResult
        },
        {
            path: "/result",
            name: "Result",
            component: MainResult
        },
        {
            path: "/recharge",
            name: "Recharge",
            component: Recharge
        },
        {
            path: "/recharge-items",
            name: "Recharge Items",
            component: RechargeItems
        },
        {
            path: "/withdrawal",
            name: "Withdrawal",
            component: Withdrawal
        },
        {
            path: "/withdrawal-items",
            name: "Withdrawal Items",
            component: WithdrawalItems
        },
        {
            path: "/notifications",
            name: "Notifications",
            component: Notifications
        },
        {
            path: "/auth/otp",
            name: "OTP",
            component: OTP
        },
        {
            path: "/auth/forgot-password",
            name: "Forgot password",
            component: ForgotPassword
        },
        {
            path: "/vnpaypayment",
            name: "VNPAY",
            component: VNPAY_Return
        },
        {
            path: "/momopayment",
            name: "Momo",
            component: Momo_Check
        }
        
    ]
};

const dashboardRoutes = {
    path: "",
    name: "Dashboard",
    containsHome: true,
    children: [
        {
            path: "/",
            name: "Trang chủ",
            component: Home,
            icon: faHome
        },
        // {
        //     path: "/gioi-thieu",
        //     name: "Giới thiệu bạn bè",
        //     component: Affiliate,
        //     icon: faUsers
        // },
        {
            path: "/shopping-history",
            name: "Lịch sử mua hàng",
            component: ShoppingHistory,
            icon: faStore
        },
        {
            path: "/payment-history",
            name: "Lịch sử giao dịch",
            component: PaymentHistory,
            icon: faMoneyBill
        },
        {
            path: "/result",
            name: "Kết quả",
            component: MainResult,
            icon: faTrophy
        },
        {
            path: "/analytics",
            name: "Thống kê bộ số",
            component: MainAnalytic,
            icon: faChartLine
        },
        {
            path: "/profile",
            name: "Thông tin tài khoản",
            component: Profile,
            icon: faUser
        },
        {
            path: "/contact",
            name: "Liên hệ",
            component: Help,
            icon: faPhone
        },
        {
            path: "/term",
            name: "Bảo mật và điều khoản",
            component: Term,
            icon: faLock
        }
    ]
};

const authRoutes = {
    path: "/auth",
    name: "Auth",
    children: [

    ]
};

const landingRoutes = {
    path: "",
    name: "Landing",
    children: [
        {
            // path: "/promotion/lucky-circle",
            // name: "Lucky Circle",
            // component: LuckyCircle
        }
    ]
};

const dashboardWithoutSidebarRoutes = {
    path: "",
    name: "Dashboard without sidebar",
    children: [
    ]
};

// Dashboard specific routes
export const dashboard = [
    dashboardRoutes,
    privacyRoutes
];

// Dashboard without sidebar
export const dashboardWithoutSidebar = [
    dashboardWithoutSidebarRoutes
];

// Landing specific routes
export const landing = [
    landingRoutes
];

// Auth specific routes
export const page = [authRoutes];

// All routes
export default [
    dashboardRoutes,
    authRoutes,
    landingRoutes,
    dashboardWithoutSidebarRoutes
];
