import React, {Component} from 'react';

import {
    Row, Col
} from "reactstrap";

class Term extends Component {
    constructor(props) {
        super(props);
        this.termContent = {
            subtitle: 'Người dùng vui lòng đọc kỹ Điều khoản sử dụng ("Điều khoản") trước khi tiến hành cài đặt bất kỳ phần nào của Ứng dụng VietlotOnline (bao gồm nhưng không giới hạn các phần mềm, các tệp dữ liệu và tài liệu liên quan) hoặc sử dụng các dịch vụ do bên thứ ba cung cấp để kết nối đến Ứng dụng. Bạn chấp thuận và đồng ý bị ràng buộc bởi các quy định và điều kiện trong Điều khoản này khi thực hiện các thao tác trên đây. Trường hợp bạn không đồng ý với bất kỳ điều khoản sử dụng nào của VietlotOnline (phiên bản này và các phiên bản cập nhật), bạn vui lòng không tải, không cài đặt, không sử dụng Ứng dụng hoặc tháo gỡ Ứng dụng ra khỏi thiết bị di động của bạn.',
            body: [
                {
                    title: 'Giới thiệu về ứng dụng',
                    subtitle: '',
                    content: [
                        '- Người dùng có thể lựa chọn các bộ số (vé số Vietlott) theo ý muốn của mình thông qua VietlotOnline, sau khi Người dùng xác nhận, VietlotOnline sẽ chuyển thông tin đơn hàng tới điểm bán Vietlott phù hợp nhất, điểm bán sẽ in vé và vận chuyển tới tận tay Người dùng có nhu cầu.',
                        '- Người dùng có thể lựa chọn hình thức uỷ quyền cho VietlotOnline giữ vé mình đã mua, vé đã mua được ghi thông tin cá nhân của Người dùng vào mặt sau và được chụp hình gửi tới Người dùng để xác định quyền sở hữu.',
                        '- VietlotOnline tự động thông báo vé Người dùng đã mua khi trúng thưởng kèm theo giá trị trúng thưởng tới Người dùng ngay sau khi có kết quả quay số của mỗi kỳ.',
                        '- VietlotOnline cung cấp những số liệu thống kê giúp Người dùng có thêm cơ sở để lựa chọn các bộ số của mình.',
                        '- VietlotOnline không cung cấp dịch vụ mua vé số Vietlott. Dịch vụ của VietlotOnline là nhằm kết nối Người dùng (Người có nhu cầu mua vé số Vietlott), với Người cung cấp (Điểm bán chính thức của Vietlott). VietlotOnline không dự định tiến hành việc cung cấp dịch vụ mua vé số Vietlott hay thực hiện các hoạt động nào có thể được xem là hoạt động của Người cung cấp dịch vụ mua vé số Vietlott. Việc cung cấp dịch vụ mua hộ vé số Vietlott cho Người dùng là tùy thuộc vào quyết định của Người cung cấp và việc sử dụng dịch vụ đó là thuộc quyền quyết định của Người dùng.',
                    ],
                },
                {
                    title: 'Quy định chung',
                    content: [
                        'Người dùng khi sử dụng VietlotOnline để tham gia dự thưởng xổ số điện toán tự chọn phải đáp ứng đầy đủ các điều kiện quy định sau:',
                        '- Là công dân Việt Nam, người Việt Nam định cư ở nước ngoài hoặc người nước ngoài nhập cảnh hợp pháp vào Việt Nam;',
                        '- Là người từ đủ 18 tuổi trở lên;',
                        '- Không phải là người bị hạn chế về năng lực hành vi dân sự hoặc mất năng lực hành vi dân sự theo quy định của pháp luật.',
                    ],
                },
                {
                    title: 'Quy trình mua vé',
                    content: [
                        'Người dùng khi sử dụng VietlotOnline để tham gia dự thưởng xổ số điện toán tự chọn sẽ tuân thủ đầy đủ theo quy trình mua vé của VietlotOnline như sau:',
                        '(1) Người dùng lựa chọn vé mua qua VietlotOnline, lựa chọn các thông tin về vé mua như:',
                        '- Loại vé chơi (Power 6/55, Mega 6/45, Max4D, ResultMax3D/3D+, Keno).',
                        '- Loại hình chơi (Vé thường, vé bao – Power 6/55, Mega 6/45; Vé thường, vé tổ hợp, vé bao, vé cuộn - Max 4D; Vé thường, vé đảo số, vé ôm vị trí, vé ôm dãy số - ResultMax3D/3D+)',
                        '- Kỳ quay số mở thưởng',
                        '- Giá tiền.',
                        '(2) Sau khi lựa chọn xong vé cần mua, Người dùng lựa chọn Hình thức nhận vé và cung cấp thông tin cá nhân đảm bảo tính sở hữu với vé mua của mình (Họ và tên, số điện thoại, số CMND/CCCD):',
                        '- Hình thức giữ vé: Ủy quyền cho VietlotOnline lưu giữ những chiếc vé mà mình đã chọn mua.',
                        '- Hình thức ship vé: Yêu cầu VietlotOnline ship những chiếc vé mình đã chọn mua đến địa chỉ mà mình yêu cầu.',
                        '(3) Sau khi lựa chọn Hình thức nhận vé, Người dùng sẽ lựa chọn hình thức thanh toán để thực hiện thanh toán cho những chiếc vé mình đã chọn mua.',
                        '(4) Khi nhận được hình ảnh thực tế của vé vật lý đã in, Người dùng, trong thời gian quy định (10 phút), cần kiểm tra lại các thông tin trên vé vật lý và Báo lỗi vé nếu có (Hotline 0964662266) bất kỳ sai sót nào (vé in sai số, sai thông tin, …) để VietlotOnline xử lý. VietlotOnline chỉ có thể xử lý vé trước giờ khóa máy của các công ty Xổ số kỳ xổ ngày hôm đó. Và nếu có tranh chấp, khiếu nại giá trị của vé cuối cùng chính là vé vật lý đã in cho Người dùng ( xin rất lưu ý vấn đề này)',
                        'Chiếc vé vật lý được in ra, ghi thông tin, chụp ảnh gửi Người dùng và được xác nhận là chính xác về mặt thông tin là chứng từ duy nhất có giá trị pháp lý để Người dùng so thưởng và nhận thưởng. Thông tin được ghi trên vé được lấy từ kênh thanh toán (Ví) của khách hàng đã đăng ký. Người dùng chịu trách nhiệm hoàn toàn về tính chính xác của thông tin mà Người dùng cung cấp cho VietlotOnline và VietlotOnline sẽ không chịu trách nhiệm với những sai sót đối với những thông tin do Người dùng cung cấp sai này.',
                        'Quy trình mua vé được xác nhận là thành công khi và chỉ khi:',
                        '- Với đơn hàng Ship: Người dùng xác nhận và ký tên vào Biên bản giao hàng theo đúng Điều 5. Quy định về vận chuyển và giao vé của Điều khoản chính sách này.',
                        '- Với đơn hàng Đại lý giữ hộ: Người dùngđã nhận được hình ảnh của vé có ghi đầy đủ thông tin và không có phản hồi Báo lỗi vé như quy định tại Điều 6: Quy định về in vé, ghi thông tin và gửi hình ảnh của vé của Điều khoản chính sách này.',
                        'Đối với những đơn hàng không thành công, VietlotOnline sẽ có nghĩa vụ và trách nhiệm gửi thông báo và  thực hiện hoàn tiền (chậm nhất sau 05 ngày làm việc) theo giá trị đơn hàng cho Người dùng.',
                        'Trong suốt quá trình mua vé và nhận thưởng, Người dùng phải tuân thủ đầy đủ các Quy định về thanh toán; về vận chuyển và giao vé; về in vé, ghi thông tin và gửi hình ảnh vé; về trả thưởng như các Quy định dưới đây.',
                    ],
                },
                {
                    title: 'Quy định về thanh toán',
                    content: [
                        'Người dùng có thể sử dụng những hình thức sau để thanh toán cho đơn hàng:',
                        '- Thanh toán qua ví điện tử',
                        '- Thanh toán qua hình thức chuyển khoản: Người dùng có thể thanh toán đơn hàng từ tài khoản ngân hàng của Người dùng vào tài khoản ngân hàng chỉ định của VietlotOnline thông qua Internet Banking/Mobile Banking.',
                        '- Thanh toán bằng hình thức trả trước: Người dùng có thể nạp một khoản tiền nhất định (là bội số của 10,000đ) vào tài khoản VietlotOnline của mình để có thể mua nhiều lần tiếp theo. Tài khoản này là tài khoản Người dùng chỉ dùng để mua Vietlott và không được rút tiền ra.',
                        'Người dùng thanh toán giá trị đơn hàng, bao gồm: (1) Giá trị của các bộ số Vietlott (được bán đúng với giá niêm yết theo quy định của Vietlott và Bộ tài chính), (2) Chi phí vận chuyển (áp dụng khi Người dùng lựa chọn hình thức giao vé tận tay), (3) phí chuyển tiền hoặc (và) phí dịch vụ của Ví điện tử, (4) các chi phí khác (nếu có) theo quy định của VietlotOnline.',
                        '- Người dùng chỉ được rút số tiền là tiền trả thưởng của Vietlott'

                    ],
                },
                {
                    title: 'Quy định về vận chuyển và giao vé',
                    content: [
                        'Áp dụng trong trường hợp Người dùng lựa chọn hình thức giao vé tận nơi, trong khoảng thời gian trước 03 tiếng đồng hồ so với giờ mở thưởng của kỳ quay số mà Người dùng chọn. VietlotOnline sẽ giao vé trong các khoảng thời gian cụ thể như sau:',
                        '- Sáng: từ 9:00 - 12:00',
                        '- Chiều: từ 15:00 - 17:30',
                        '- Ship nhanh: tuỳ thuộc theo chính sách kinh doanh của VietlotOnline được áp dụng trong các điều kiện và thời gian được thông báo công khai.',
                        'Trường hợp không giao được vé:',
                        '- Khách quan: Tai nạn, tắc đường, thiên tai, lũ lụt, người giao vé không thể giao tới tay Người dùng trước thời điểm có kết quả quay thưởng. Trong trường hợp này, VietlotOnline sẽ gửi thông báo cho Người dùng trước giờ có kết quả mở thưởng và thực hiện hoàn tiền (chậm nhất sau 05 ngày làm việc) theo giá trị đơn hàng cho Người dùng',
                        '- Chủ quan: người giao vé không thể liên lạc được với Người dùng (sau tối thiểu 3 lần gọi điện, chụp hình địa chỉ giao vé). Trong trường hợp này, vé Vietlott do Người dùng đặt sẽ được chuyển về điểm bán đã in vé (Người dùng nhậnđược đầy đủ thông tin của Đại lý in vé trong mỗi lần mua: địa chỉ, số điện thoại). Người dùng chủ động tới điểm bán đó để lấy vé. Chi phí vận chuyển và giao vé vẫn được tính trong trường hợp này. Người dùng sẽ phải chịu trách nhiệm cho bất kỳ rủi ro nào trong trường hơp Người dùng quên không tới điểm bán lấy vé.',
                        'Cách tính phí vận chuyển và giao vé:',
                        '- Giao vé trong nội thành (Hà Nội, Hồ Chí Minh): 20.000đ/1 đơn hàng',
                        '- Giao vé tại các tỉnh: 10.000đ- 20.000đ/1 đơn hàng',
                        'VietlotOnline sẽ công bố danh sách các tỉnh thành áp dụng hình thức giao vé tận tay tại website và trên Ứng dụng. Những tỉnh thành còn lại Người dùng vẫn có thể mua vé bằng hình thức Uỷ quyền Đại lý giữ hộ.',
                    ],
                },
                {
                    title: 'Quy định về in vé, ghi thông tin và gửi hình ảnh vé',
                    content: [
                        'Áp dụng trong trường hợp Người dùng lựa chọn hình thức Uỷ quyền VietlotOnline giữ hộ:',
                        '- Đối với các vé tham gia dự thưởng các kỳ quay số có các giải thưởng trên 10 tỷ đồng (Mega 6/45; Power 6/55), vé sau khi được in ra sẽ được ghi các thông tin: Mã đơn hàng, Họ và tên, CMND/CCCD/Hộ chiếu của Người dùng lên mặt sau của vé.',
                        '- Đối với các vé tham gia dự thưởng các kỳ quay số có các giải thưởng dưới 10 tỷ đồng (Max4D, ResultMax3D/3D+, Keno), vé sau khi được in ra sẽ được ghi các thông tin: Mã đơn hàng lên mặt sau của vé.',
                        '- Vé của Người dùng sau khi được in ra và ghi thông tin theo đúng quy định, sẽ được chụp hình ảnh chi tiết hai mặt vé và gửi lại cho Người dùng qua Ứng dụng và email đăng kí của Người dùng (Khuyến nghị: Để nhận được thông báo thông tin kịp thời, Người dùng lưu ý cập nhật địa chỉ email đang thường xuyên sử dụng).',
                        '- Chiếc vé vật lý được in ra, ghi thông tin, chụp ảnh gửi Người dùng và được xác nhận là chính xác về mặt thông tin là chứng từ duy nhất có giá trị pháp lý để Người dùng so thưởng và nhận thưởng. Quý khách sau khi nhận được hình ảnh chiếc vé vật lý cần kiểm tra kỹ lưỡng các thông tin trên vé và trong trường hợp phát hiện sai sót cần sử dụng chức năng Báo lỗi vé trong thời gian quy định để VietlotOnline xử lý.',
                        'Người dùng chịu trách nhiệm hoàn toàn về tính chính xác của thông tin mà Người dùng cung cấp cho VietlotOnline. Trong vòng 10 (mười) phút sau khi Người dùng nhận được hình ảnh chi tiết hai mặt vé qua Ứng dụng và email đăng ký của Người dùng, nếu không có sự phản hồi nào của Người dùng đến VietlotOnline thì mặc định các thông tin trên vé vật lý được xác nhận là chính xác, và là sự lựa chọn cuối cùng của Người dùng.',
                    ],

                },
                {
                    title: 'Quy định về hoàn tiền và hủy vé',
                    content: [
                        'Trong các trường hợp sau, vé do Người dùng đặt mua sẽ được hủy và hoàn tiền (Chậm nhất sau 5 ngày làm việc):',
                        '-   Bộ số mà Người dùng đặt mua đã hết ( Max4D, max3D+).',
                        '-   Vé đã in, ghi thông tin và chụp ảnh gửi đến Người dùng nhận được phản hồi từ Người dùng có sự sai sót thông tin.',
                        '-   Vé mà Người dùng đặt mua vì những lý do bất khả kháng (ví dụ: Hệ thống gặp sự cố về đường truyền) mà VietlotOnline không kịp ghi thông tin và chụp ảnh gửi đến Người dùng.',
                    ],
                },
                {
                    title: 'Quy định về trả thưởng',
                    content: [
                        'Trong trường hợp Người dùng lựa chọn hình thức uỷ quyền cho VietlotOnline giữ vé:',
                        '-       Theo  quy định của Vietlott, Đại lý cấp 1 và các điểm bán trong hệ thống được Vietlott uỷ quyền trả thưởng cho Người dùng đối với các giải thưởng có giá trị từ 10 tỷ đồng trở xuống.',
                        '-       Dựa trên hợp đồng hợp tác kinh doanh số 01/2018/LB-PWG với Power Group, đại lý cấp 1 có quy mô hoạt động lớn nhất của Vietlott, để thuận tiện cho Người dùng, VietlotOnline sẽ chủ động ứng tiền trúng thưởng trước cho Người dùng trước, sau đó nộp vé trúng thưởng và thanh toán với Đại lý Power Group trong thời gian không quá 5 ngày làm việc.',
                        '-       Theo đó, khi trúng thưởng, toàn bộ tiền thưởng sẽ được chuyển vào Tài khoản trả thưởng của Người dùng sau khi khấu trừ thuế thu nhập cá nhân. Người dùng có thể tuỳ ý chuyển tiền từ Tài khoản trả thưởng:',
                        '    ●	Vào Tài khoản VietlotOnline (Tài khoản VietlotOnline chỉ dùng để mua vé và không được rút ra).',
                        '●	Thành tiền nạp điện thoại cho mình hoặc người thân.',
                        '-       Khi Tài khoản trả thưởng vượt quá 500.000đ, Người dùng có thể rút tiền từ Tài khoản trả thưởng bằng các hình thức và theo nguyên tắc sau:',
                        '   (1)   Chuyển khoản vào tài khoản ngân hàng của Người dùng chỉ định và (hoặc)',
                        '(2)   Nạp vào ví điện tử của Người dùng.',
                        '   Với hình thức (1): Mức phí chuyển khoản vào tài khoản ngân hàng chỉ định của Người dùng được áp dụng như sau:',
                        '-        Từ trên 500,000đ tới 20,000,000đ: 5% tổng số tiền chuyển khoản',
                        '-        Từ trên 20,000,000đ tới 50,000,000đ: 2,5% tổng số tiền chuyển khoản',
                        '-        Từ trên 50,000,000đ: 2% tổng số tiền chuyển khoản',
                        '●	Đối với vé trúng thưởng trên 50,000,000đ: Theo quy định của Vielott và Đại lý cấp1, không quá 60 ngày kể từ ngày trúng thưởng, Người dùng cần cung cấp cho VietlotOnline các thông tin sau để làm thủ tục nhận thưởng: CMND/CCCD/Hộ chiếu, Hộ khẩu.',
                        '●	Đối với vé trúng thưởng trên 10 tỷ, người trúng thưởng phải đến trụ sở của VietlotOnline (mang theo CMND/CCCD/Hộ chiếu, Hộ khẩu) để nhận lại vé trúng thưởng. VietlotOnline sẽ hỗ trợ người trúng thưởng hoàn thiện hồ sơ & thủ tục nhận thưởng theo quy định của Vietlott và Đại lý cấp1.',
                        'Với hình thức (2): Mức phí chuyển tiền vào ví điện tử: theo quy định của từng ví điện tử mà Người dùng đang sử dụng.',
                        '-       Thời gian nhận thưởng: không quá 05 ngày làm việc kể từ khi nhận được yêu cầu và thông tin chuyển tiền của Người dùng (đối với trường hợp Người dùng trúng thưởng từ trên 50 triệu tới 10 tỷ đồng thì thời gian bắt đầu được tính khi nhận được đầy đủ các giấy tờ xác minh của Người dùng).',
                        'Trong trường hợp Người dùng lựa chọn Đại lý giữ hộ thì vé sẽ được che một phần mã vạch của vé khi chụp ảnh gửi lại cho Người dùng: Theo quy định của Vietlott, Đại lý cấp 1 và quy định trả thưởng của VietlotOnline, VietlotOnline sẽ chịu trách nghiệm và trực tiếp trả thưởng cho Người dùng đặt mua hộ vé số qua Ứng dụng của VietlotOnline với tất cả giải thưởng dưới từ 10 tỷ trở xuống. Nên khi Người dùng trúng thưởng, nghĩa vụ trả thưởng là của VietlotOnline đối với các giải thưởng dưới 10 tỷ. Nếu VietlotOnline để cho Người dùng biết toàn bộ mã vạch của vé, trong trường hợp để bạn bè hoặc người thân của Người dùng thấy mã vạch và đi trả thưởng ở bên ngoài, VietlotOnline lúc đó không thể hoàn thành được nghĩa vụ trả thưởng vé đó cho người sở hữu. Trong trường hợp đó, có thể xảy ra xung đột về quyền lợi của Người dùng và VietlotOnline, Người dùng có thể cáo buộc VietlotOnline chưa hoàn thành nghĩa vụ trả thưởng. VietlotOnline xác định đây là một rủi ro lớn cho VietlotOnline. Vì vậy, để đảm bảo quyền lợi cho VietlotOnline và người chơi, VietlotOnline quyết định không công bố toàn vẹn mã vạch của vé số.',
                    ],
                },
                {
                    title: 'Giới hạn trách nhiệm',
                    content: [
                        'Người dùng đồng ý bảo vệ, bồi thường và giữ cho VietlotOnline và các công ty liên kết của VietlotOnline không bị tổn hại bởi bất kỳ khiếu nại, chi phí, phán quyết của toà án, bao gồm nhưng không giới hạn phí luật sư, liên quan đến hoặc phát sinh từ việc vi phạm điều khoản sử dụng hoặc bất kỳ sự sử dụng của Người dùng, hoặc bất kỳ người nào khác sử dụng dịnh vụ thông qua Người dùng, hoặc sử dụng điện thoại, máy tính của Người dùng.',
                        '-       Người dùng đồng ý cho phép VietlotOnline sử dụng dữ liệu cá nhân của mình (Bao gồm Họ tên, số điện thoại) và chuyển giao những dữ liệu này đến Người cung cấp phục vụ cho mục đích cung cấp dịch vụ. Người dùng đồng thời cũng cho phép VietlotOnline được tự do chuẩn bị, thu thập, lưu trữ và cập nhật dữ liệu cá nhân trong phạm vi và thời gian cần thiết trong quá trình VietlotOnline cung cấp dịch vụ ứng dụng.',
                        '-       Người dùng đồng ý và chấp nhận rằng việc chuẩn bị, thu thập, lưu trữ, sử dụng và truyền tải dữ liệu cá nhân của mình của VietlotOnline là tuân theo Chính sách Bảo mật và Thu thập Dữ liệu Cá Nhân (Chính sách Bảo mật) được công bố và cập nhật định kì tại Điều khoản sử dụng này. Người dùng cũng chấp nhận đọc và hiểu rõ Chính sách Bảo mật trước khi đồng ý với các Điều kiện sử dụng.',
                        '-       VietlotOnline có thể điều chỉnh các Điều kiện sử dụng nói trên tùy theo thời gian mà không cần thông báo trước.',
                        '-       Trong việc cung cấp ứng dụng VietlotOnline, VietlotOnline không hoạt động như một đại lý hoặc đại diện cho Người dùng hay Người cung cấp.',
                        '-       Người dùng phải chấp nhận một cách đầy đủ và vô điều kiện nội dung hiện hành của các Điều kiện sử dụng cho từng lần sử dụng dịch vụ.',
                    ],
                },
                {
                    title: 'Điều 10: Chính sách bảo mật',
                    content: [
                        'Ứng dụng mua hộ vé số Vietlott VietlotOnline và trang web công ty(gồm cả nền tảng website, mobile website), sau đây gọi chung là “VietlotOnline”, cam kết sẽ bảo mật những thông tin mang tính riêng tư của Người dùng. Quý khách vui lòng đọc “Chính sách bảo mật” dưới đây trước khi truy cập những nội dung khác để hiểu hơn những cam kết mà VietlotOnline thực hiện, nhằm tôn trọng và bảo vệ quyền lợi của người truy cập:',
                        '10.1.   Thu thập thông tin cá nhân:',
                        'VietlotOnline thực hiện việc thu thập các thông tin cá nhân mà Quý khách cung cấp trực tiếp cho VietlotOnline bao gồm:',
                        '- Họ và tên',
                        '- Số Chứng minh nhân dân/Căn cước công dân',
                        '- Số điện thoại',
                        '- Địa chỉ email',
                        '- Địa chỉ nhận giao vé',
                        'Khi Người dùng thực hiện một trong số các hoạt động sau qua VietlotOnline:',
                        '- Thực hiện đăng ký tài khoản',
                        '- Thực hiện đặt mua vé, ship vé',
                        '- Thực hiện nhận thưởng',
                        'Các thông tin thu thập sẽ giúp VietlotOnline:',
                        '● Hỗ trợ Người dùng khi mua vé',
                        '● Giải đáp thắc mắc Người dùng.',
                        '● Cung cấp cho quý khách thông tin mới nhất của VietlotOnline.',
                        '● Xem xétvà nâng cấp nội dung và giao diện của website và ứng dụng.',
                        'Thực hiện các hoạt động quảng bá liên quan đến các sản phẩm và dịch vụ của VietlotOnline',
                        'VietlotOnline cũng có thể thu thập thông tin về số lần ghé thăm, bao gồm số trang quý khách xem, số liên kết (links) quý khách click và những thông tin khác liên quan đến việc kết nối đến VietlotOnline. VietlotOnline cũng thu thập các thông tin mà trình duyệt web (browser) quý khách sử dụng mỗi khi truy cập vào VietlotOnline, bao gồm: địa chỉ IP, loại browser, ngôn ngữ sử dụng, thời gian và những địa chỉ mà browser truy xuất đến.',
                        'Các chủ thể (Người hoặc Tổ chức) được phép tiếp cận, sử dụng các thông tin cá nhân đươc thu thập ở trên được quy định chi tiết tại điều 10.2 và 10.3 bên dưới.',
                        '10.2.   Sử dụng thông tin cá nhân:',
                        '-       Chỉ duy nhất VietlotOnline được quyền thu thập, tiếp cận và sử dụng thông tin cá nhân quý khách với mục đích phù hợp và hoàn toàn tuân thủ nội dung của “Chính sách bảo mật” này.',
                        '-       Khi cần thiết, VietlotOnline có thể sử dụng những thông tin này để liên hệ trực tiếp với quý khách dưới các hình thức như email, điện thoại, tin nhắn. Quý khách cũng có thể nhận được email cung cấp thông tin sản phẩm, dịch vụ mới, thông tin về các sự kiện sắp tới hoặc thông tin tuyển dụng nếu quý khách đăng ký nhận email thông báo.',
                        '10.3.   Chia sẻ thông tin cá nhân:',
                        '-       Ngoại trừ các trường hợp về "Sử dụng thông tin cá nhân" như đã nêu trong chính sách này, VietlotOnline cam kết sẽ không tiết lộ thông tin cá nhân quý khách ra ngoài.',
                        '-       Trong một số trường hợp, VietlotOnline có thể thuê một đơn vị độc lập để tiến hành các dự án nghiên cứu thị trường và khi đó thông tin của quý khách sẽ được cung cấp cho đơn vị này để tiến hành dự án. Bên thứ ba này sẽ bị ràng buộc bởi một thỏa thuận về bảo mật mà theo đó họ chỉ được phép sử dụng những thông tin được cung cấp cho mục đích hoàn thành dự án.',
                        '-       VietlotOnline có thể tiết lộ hoặc cung cấp thông tin cá nhân của quý khách trong các trường hợp thật sự cần thiết như sau:',
                        '1. a)Khi có yêu cầu của các cơ quan pháp luật;',
                        '2. b)Trong trường hợp mà VietlotOnline tin rằng điều đó sẽ giúp VietlotOnline bảo vệ quyền lợi chính đáng của VietlotOnline trước pháp luật;',
                        '3. c)Tình huống khẩn cấp và cần thiết để bảo vệ quyền an toàn cá nhân của các thành viên VietlotOnline.',
                        '10.4.   Truy xuất thông tin cá nhân: ',
                        'Bất cứ thời điểm nào quý khách cũng có thể truy cập và chỉnh sửa những thông tin cá nhân của mình theo các liên kết thích hợp mà VietlotOnline cung cấp.',
                        '10.5.   Bảo mật thông tin cá nhân:',
                        '-      Khi quý khách gửi thông tin cá nhân cho VietlotOnline, quý khách đã đồng ý với các điều khoản mà VietlotOnline đã nêu ở trên, VietlotOnline cam kết bảo mật thông tin cá nhân của quý khách bằng mọi cách thức có thể. Hoạt động Thương mại điện tử của VietlotOnline, bao gồm việc đảm bảo khả năng bảo mật thông tin của Người dùng trong quá trình giao dịch đã được chứng nhận bởi Bộ Công Thương.',
                        '-      VietlotOnline cũng khuyến cáo quý khách nên tuân thủ các nguyên tắc sau để có thể bảo vệ thông tin cá nhân của mình, gồm:',
                        '● Không tiết lộ Tên sử dụng/Tên đăng nhập và/hoặc Mật khẩu với bất kỳ ai, viết hoặc sử dụng ở nơi mà mọi người có thể nhận biết và nhìn thấy được.',
                        '● Khi chọn mật khẩu quý khách không nên chọn những thông tin dễ xác định như họ tên, ngày sinh, số điện thoại hoặc một số ký tự, con số dễ nhận biết từ tên, ngày sinh, số điện thoại của quý khách.',
                        '● Thoát khỏi hệ thống và trình duyệt khi rời khỏi máy tính, ngay cả trong thời gian ngắn. Nếu sử dụng máy tính chung nhiều người, quý khách nên đăng xuất, hoặc thoát hết tất cả cửa sổ website đang mở.',
                        '● Khi nghi ngờ mật khẩu đã bị lộ, quý khách thay đổi mật khẩu ngay lập tức, trong trường hợp cần thiết quý khách lập tức thông báo và đề nghị được hỗ trợ từ VietlotOnline.',
                        '● Trongtrường hợp các giao dịch, hành vi trái pháp luật được tiến hành với tài khoản hoặc thông thông tin cá nhân của quý khách mà không có sự ủy quyền hoặc do sự sai sót, vô ý hoặc cố ý từ phía quý khách, VietlotOnline hiểu rằng quý khách sẽ chịu toàn bộ tổn thất hoặc trách nhiệm theo quy định pháp luật khi các giao dịch đó được thực hiện.',
                        '10.6.   Thời gian lưu trữ thông tin:',
                        '-    Dữ liệu cá nhân của người dùng sẽ được lưu trữ cho đến khi có yêu cầu hủy bỏ hoặc tự người dùng đăng nhập và thực hiện hủy bỏ. Còn lại trong mọi trường hợp thông tin cá nhân của người dùng sẽ được bảo mật trên máy chủ của VietlotOnline. ',
                        '10.7  Thanh toán an toàn:',
                        '-       Mọi Người dùng tham gia giao dịch tại VietlotOnline qua thẻ tín dụng/thẻ ghi nợ/thẻ ATM nội địa đều được bảo mật thông tin bằng mã hóa. Bên cạnh đó, khi thực hiện thanh toán qua mạng, quý khách vui lòng lưu ý các chi tiết sau:',
                        '● Chỉ thanh toán trên website có chứng chỉ an toàn, bảo mật hệ thống thẻ.',
                        '● Tuyệt đối không cho người khác mượn thẻ tín dụng hoặc tài khoản của mình để thực hiện thanh toán trên website; trong trường hợp phát sinh giao dịch ngoài ý muốn, Người dùng vui lòng thông báo ngay lập tức cho VietlotOnline để VietlotOnline có thể hỗ trợ kịp thời.',
                        '● Kiểm tra tài khoản ngân hàng của mình thường xuyên để đảm bảo tất cả giao dịch qua thẻ đều nằm trong tầm kiểm soát.',
                        '10.7.   Sử dụng “cookie”:',
                        '-       Cookie là một file văn bản được đặt trên đĩa cứng của quý khách bởi một máy chủ của trang web. Cookie không được dùng để chạy chương trình hay đưa virus vào máy tính của quý khách. Cookie được chỉ định vào máy tính của quý khách và chỉ có thể được đọc bởi một máy chủ trang web trên miền được đưa ra cookie cho quý khách.',
                        '-       VietlotOnline dùng "cookie" để giúp cá nhân hóa và nâng cao tối đa hiệu quả sử dụng thời gian trực tuyến của quý khách khi truy cập VietlotOnline mà không cần đăng ký lại thông tin sẵn có.',
                        '-       Quý khách có thể chấp nhận hoặc từ chối dùng cookie. Hầu hết những browser tự động chấp nhận cookie, nhưng quý khách có thể thay đổi những cài đặt để từ chối tất cả những cookie nếu quý khách thích. Tuy nhiên, nếu quý khách chọn từ chối cookie, điều đó có thể gây cản trở và ảnh hưởng không tốt đến một số dịch vụ và tính năng phụ thuộc vào cookie tại VietlotOnline.',
                        '10.8.   Quy định về “spam”:',
                        '-       Thư rác (spam) là các email giả mạo danh tín VietlotOnline gửi đi. VietlotOnline khẳng định chỉ gửi email đến quý khách khi và chỉ khi quý khách có đăng ký hoặc sử dụng dịch vụ từ hệ thống của VietlotOnline.',
                        '-       VietlotOnline cam kết không bán, thuê lại hoặc cho thuê email của quý khách từ bên thứ ba. Nếu quý khách vô tình nhận được email không theo yêu cầu từ hệ thống VietlotOnline do một nguyên nhân ngoài ý muốn, xin vui lòng nhấn vào link từ chối nhận email kèm theo hoặc thông báo trực tiếp đến VietlotOnline.',
                        '10.9.   Thay đổi về chính sách:',
                        '-       VietlotOnline hoàn toàn có thể thay đổi nội dung trong “Chính sách bảo mật” mà không cần phải thông báo trước, để phù hợp với các nhu cầu của VietlotOnline cũng như nhu cầu được phản hồi từ Người dùng. Khi cập nhật nội dung chính sách này, VietlotOnline sẽ chỉnh sửa lại thời gian “Cập nhật lần cuối” bên dưới.',
                        '-       Nội dung “Chính sách bảo mật” này chỉ áp dụng tại VietlotOnline, không bao gồm hoặc liên quan đến các bên thứ ba đặt quảng cáo hay có link tại VietlotOnline. VietlotOnline khuyến khích quý khách đọc kỹ "Chính sách An toàn và Bảo mật" của các trang web của bên thứ ba trước khi cung cấp thông tin cá nhân cho các trang web đó.\n',
                    ],
                },
                {
                    title: 'Điều 11: Cập nhật',
                    content: [
                        'Điều khoản này có thể được cập nhật thường xuyên bởi Công ty Cổ phần VietlotOnline phiên bản cập nhật sẽ được VietlotOnline công bố tại website công ty. Phiên bản cập nhật sẽ thay thế cho các quy định và điều khoản trong thỏa thuận ban đầu. Bạn có thể truy cập vào Ứng dụng hoặc vào website trên để xem nội dung chi tiết của phiên bản cập nhật.\n',
                    ],
                },
                {
                    title: 'Điều 12: Quyền sở hữu Ứng dụng',
                    content: [
                        'Ứng dụng này được phát triển và sở hữu bởi Công ty Cổ phần VietlotOnline, tất cả các quyền sở hữu trí tuệ liên quan đến Ứng dụng (bao gồm nhưng không giới hạn mã nguồn, hình ảnh, dữ liệu, thông tin, nội dung chứa đựng trong Ứng dụng; các sửa đổi bổ sung, cập nhật của Ứng dụng) và các tài liệu hướng dẫn liên quan (nếu có) sẽ thuộc quyền sở hữu duy nhất bởi Công ty Cổ phần VietlotOnline và không cá nhân, tổ chức nào được phép sao chép, tái tạo, phân phối, hoặc hình thức khác xâm phạm tới quyền của chủ sở hữu nếu không có sự đồng ý và cho phép bằng văn bản của Công ty Cổ phần VietlotOnline.\n',
                    ],
                },
                {
                    title: 'Điều 13: Tài khoản',
                    content: [
                        'Để sử dụng Ứng dụng bạn phải tạo một tài khoản theo hướng dẫn của VietlotOnline, bạn cam kết rằng việc sử dụng tài khoản phải tuân thủ các quy định của Công ty Cổ phần VietlotOnline, đồng thời tất cả các thông tin bạn cung cấp cho VietlotOnline là chính xác, đầy đủ tại thời điểm được yêu cầu. Mọi quyền lợi và nghĩa vụ của bạn sẽ căn cứ trên thông tin tài khoản của bạn đã đăng ký.',
                        'Bạn có quyền sử dụng Ứng dụng và các dịch vụ khác mà VietlotOnline cung cấp, tuy nhiên việc sử dụng đó sẽ không bao gồm các hành vi sau đây nếu không có sự đồng ý bằng văn bản của VietlotOnline:',
                        '-       Sao chép, chỉnh sửa, tái tạo ra sản phẩm mới hoặc phiên bản tái sinh trên cơ sở Ứng dụng VietlotOnline;',
                        '-       Bán, chuyển giao, cấp quyền lại, tiết lộ hoặc hình thức chuyển giao khác hoặc đưa một phần hoặc toàn bộ Ứng dụng cho bất kỳ bên thứ ba;',
                        '-       Sử dụng Ứng dụng để cung cấp dịch vụ cho bất kỳ bên thứ ba (tổ chức, cá nhân);',
                        '-       Di chuyển, xóa bỏ, thay đổi bất kỳ thông báo chính đáng hoặc dấu hiệu nào của Ứng dụng (bao gồm nhưng không giới hạn các tuyên bố về bản quyền);',
                        '-       Thiết kế lại, biên dịch, tháo gỡ, chỉnh sửa, đảo lộn thiết kế của Ứng dụng, hoặc nội dung Ứng dụng;',
                        '-       Sử dụng, bán, cho mượn, sao chép, chỉnh sửa, kết nối tới, phiên dịch, phát hành, công bố các thông tin',
                        '-       Sử dụng hình ảnh đại diện (avatar) là logo VietlotOnline hoặc các hình ảnh vi phạm thuần phong mỹ tục, cập nhật hình ảnh trong album là logo VietlotOnline, đặt tên tài khoản cá nhân là VietlotOnline hoặc các tên tài khoản có thể gây nhầm lẫn là VietlotOnline.',
                        '-       Sử dụng Ứng dụng để thực hiện các hành động trái pháp luật, bao gồm nhưng không giới hạn: chuyển tiền trái pháp luật, rửa tiền.',
                        '-       Các hình thức vi phạm khác.',
                        'Người dùng chịu hoàn toàn trách nhiệm về nội dung do mình chia sẻ và ý kiến, quan điểm đưa ra thảo luận trên Ứng dụng.',
                        'Công ty Cổ phần VietlotOnline có quyền loại bỏ bất cứ nội dung nào VietlotOnline cho rằng vi phạm các quy định trên.\n',
                    ],
                },
                {
                    title: 'Điều 14:  Xử lý vi phạm',
                    content: [
                        'Trường hợp bạn vi phạm bất kỳ Quy định nào trong Điều khoản này, Công ty Cổ phần VietlotOnline có quyền ngay lập tức khóa tài khoản của bạn và/ hoặc xóa bỏ toàn bộ các thông tin, nội dung vi phạm, đồng thời tùy thuộc vào tính chất, mức độ vi phạm bạn sẽ phải chịu trách nhiệm trước cơ quan có thẩm quyền, Công ty Cổ phần VietlotOnline và bên thứ ba về mọi thiệt hại gây ra bởi hoặc xuất phát từ hành vi vi phạm của bạn.\n',
                    ],
                },
            ],
        };
    }


    render() {
        return (
            <div className="bootstrap-reset">
                <div style={{width: "100%", height: "100%", position: "absolute", top: 0, left: 0}} className="d-flex justify-content-center align-items-center">
                    
                </div>
                <div className="p-2">
                    {
                        this.termContent.body.map(({title, subtitle, content}, index) => (
                            <div key={index} className="mt-2">
                                <Row className="font-weight-bold">{index + 1}. {title}</Row>
                                <Row>
                                    <Col>
                                        {
                                            content.map((text, index2) => (
                                                <Row key={`${index}-${index2}`}>
                                                    {text}
                                                </Row>
                                            ))
                                        }
                                    </Col>
                                </Row>
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }
}

export default Term;
