import React from "react";

import { Col, Row } from "reactstrap";

import apiMyAccount from "../../api/MyAccount";

import { Grid, HardDrive, Video } from "react-feather";
import Dashboard from "../../layouts/Dashboard";

import LoadingPage from "../../components/LoadingPage";
import ButtonLoading from "../../components/FworkTag/ButtonLoading";
import apiResult from "../../api/Result";
import {ResultKenoCard} from '../Results/MainResult'
import utils from "../../utils";

import {Link} from "react-router-dom";

class KenoLive extends React.Component {
  constructor(props) {
    super(props);
    this.lastPeriodId = 0;
    this.state = {
      paymentStatus: "waitting",
    };
  }

  componentDidMount() {
    this.handleRefresh();
    const script = document.createElement("script");

    script.src = "/liveVideo.js";
    script.async = true;

    document.body.appendChild(script);
  }
  componentWillUnmount(){
    window.location.reload();
  }
 
  handleRefresh() {
    this.setState({ isLoaded: false });
    apiResult.getLastPeriod(6, (err, result) => {
      if (err) {
      } else {
        let id = result.id;
        this.lastPeriodId = id;
        apiResult.getResult(6, [id - 9, id], (err, result) => {
          if (err) {
          } else {
            this.lastPeriodId = id - 10;
          result.reverse();
            this.setState({ result: result, isLoaded: true });
          }
        });
      }
    });
  }
  handleLoadMore() {
    if (this.state.isLoading) {
      return;
    }
    this.setState({ isLoading: true });
    apiResult.getResult(
      6,
      [this.lastPeriodId - 9, this.lastPeriodId],
      (err, result) => {
        if (err) {
          this.setState({ isLoading: false });
        } else {
         result.reverse();
          let last_result = JSON.parse(JSON.stringify(this.state.result));
          last_result = last_result.concat(result);
          this.setState({
            isLoading: false,
            result: last_result,
          });
          this.lastPeriodId -= 10;
        }
      }
    );

  }
  renderResultCard(result, key) {
        return <ResultKenoCard result={result} key={key} />;
  }

  generateContent() {
    return (
      <>
        {this.state.result.map((result, index) => (
          <Col key={index}>
            <Row className="border-bottom p-2">
              <Col>
                <Row className="mb-1">
                  <Col className="col-3">Kỳ: #{result.id}</Col>
                  <Col className="text-right">Ngày: #{result.date}</Col>
                </Row>
                {this.renderResultCard(result, index)}
              </Col>
            </Row>
          </Col>
        ))}
        <ButtonLoading
          className="w-100"
          loading={this.state.isLoading}
          title="Xem thêm"
          onClick={this.handleLoadMore.bind(this)}
        />
      </>
    );
  }
  checkBrowser(){
    var isIphone = /(iPhone)/i.test(navigator.userAgent);
    var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);

    if( isIphone|| isSafari)
      return true;
    return false;
  }
  render() {

    
    return (
      <div className="home-page bg-white">
       
        <div style={{display:'flex',flexDirection:'column',height:'100%'}}>
        <div className='bootstrap-reset' id="iframeContainer" style={{display:'flex',flexDirection:'column',height:'100%',overflowY:'auto'}}>
                  
       <div id='iframe' style={{flex:1}} ></div>   
                  
        <div id='sh' style={{overflowY:'scroll',flex:2}}>    
        {this.state.isLoaded &&this.generateContent()}
        </div>
        </div>
        <div className="home-page__footer py-2 d-flex">
                    <div className="w-50 text-center">
                        <Link to={"/"} className="link-unset">
                            <div>
                                <Grid color={window.location.pathname === "/" ? "#169714" : "grey"}/>
                            </div>
                            <div>
                                <span
                                    style={{
                                        fontSize: "0.875rem",
                                        color: window.location.pathname === "/" ? "#169714" : "grey"
                                    }}
                                >Đặt vé</span>
                            </div>
                        </Link>
                    </div>
                    <div className="w-50 text-center">
                        <Link to={"/truc-tiep-keno"} className="link-unset">
                            <div>
                            <div class="livenow">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>                  
                            </div>
                            </div>
                            <div>
                                <span
                                    style={{
                                        fontSize: "0.875rem",
                                        color: window.location.pathname === "/truc-tiep-keno" ? "#169714" : "grey"
                                    }}
                                >Trực tiếp keno</span>
                            </div>
                        </Link>
                    </div>
                    <div className="w-50 text-center">
                        <Link to={"/result"} className="link-unset">
                            <div>
                                <HardDrive color={window.location.pathname === "/result" ? "#169714" : "grey"}/>
                            </div>
                            <div>
                                <span
                                    style={{
                                        fontSize: "0.875rem",
                                        color: window.location.pathname === "/result" ? "#169714" : "grey"
                                    }}
                                >Kết quả</span>
                            </div>
                        </Link>
                    </div>
                </div>
        </div>
        
      </div>
    );
  }
}

export async function getStaticProps() {
  var seo = await utils.fetchSeo("tructiepkeno.json");
  return {
    props: {
      seo,
    },
    revalidate: 30,
  };
}
export default KenoLive;
