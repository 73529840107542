import React, {Component} from 'react';
import moment from "moment";

import {
    Grid, HardDrive
} from "react-feather"

import colors from "../../utils/colors";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import {Link} from "react-router-dom";

import utils from "../../utils";

import api from "../../api/Result";

var Carousel = require('react-responsive-carousel').Carousel;

const menuCardData = [
    {
        productId: 7,
        lottery: "235",
        color: colors.redColor,
      },
    {
        productId: 6,
        lottery: "kenobao",
        color: colors.redColor,
      },
      {
        productId: 6,
        lottery: "keno",
        color: colors.redColor,
      },
      {
        productId: 1,
        lottery: "mega645",
        color: colors.blueColor,
      },
      {
        productId: 2,
        lottery: "power655",
        color: colors.greenColor,
        
      },{
        productId: 12,
        lottery: "max3d_pro",
        color: colors.purpleColor,
      },
     {
        productId: 5,
        lottery: "max3d_plus",
        color: colors.primaryRedColor,
      },
      {
        productId: 4,
        lottery: "max3d",
        color: colors.facebookColor,
      },
   
];

const logo = {
    'mega645': require('../../assets/images/logo/mega645.png'),
    'power655': require('../../assets/images/logo/power655.png'),
    'max4d': require('../../assets/images/logo/max4d.png'),
    'max3d': require('../../assets/images/logo/max3d.png'),
    'max3d_plus': require('../../assets/images/logo/max3d_plus.png'),
    'keno': require('../../assets/images/logo/keno.png'),
    '235': require("../../assets/images/logo/dien-toan.png"),
    kenobao: require('../../assets/images/logo/keno.png'),
    max3d_pro:require('../../assets/images/logo/logomax3dpro.png'),
    'vietlottkeno':require('../../assets/images/logo/keno.png'),
};

class MenuCard extends React.Component {
    constructor(props) {
        super(props);
        this.itv = null;
        this.countDownDate = new Date(utils.generateCountDownDate(6)).getTime();
        this.state = {
            date: moment().format('DD/MM/YYYY HH:MM:SS'),
            time: '00:00:00:00',
            nextDate: '01/01/2020',
            nextDateTime: '01/01/2020 18:00:00',
        };
    }

    timeGenerate() {
        return this.state.time;
    }

    handleRefresh() {
       

        api.getNearestTerm(this.props.productId, (err, result) => {
            if (err) {

            } else {
                const that = this;
                if (this.itv) {
                    clearInterval(this.itv);
                }
                if (this.props.productId !== 6) {
                    let nextDate = result.date;
                    nextDate = moment(nextDate, 'DD-MM-YYYY');
                    nextDate.set('hour', 18);
                    nextDate.set('minute', 0);
                    nextDate.set('second', 0);

                    this.setState({
                        nextDateTime: nextDate.format('DD/MM/YYYY HH:MM:SS'),
                        nextDate: nextDate.format('DD/MM/YYYY'),
                    });
                    that.itv = setInterval(function () {
                        let countDown = (nextDate - moment()) / 1000;
                        let day = Math.floor(countDown / 60 / 60 / 24);
                        let hour = 0;
                        let minute = 0;
                        let second = 0;
                        hour = Math.floor((countDown - day * 24 * 60 * 60) / 60 / 60);
                        minute = Math.floor((countDown - day * 24 * 60 * 60 - hour * 60 * 60) / 60);
                        second = Math.floor((countDown - day * 24 * 60 * 60 - hour * 60 * 60 - minute * 60));
                        if ((day === 0 && hour === 0 && minute === 0 && second === 0) || day < 0) {
                            clearInterval(that.itv);
                            that.handleRefresh();
                            return;
                        }
                        let str = `${utils.formatValue(day)}:${utils.formatValue(hour)}:${utils.formatValue(minute)}:${utils.formatValue(second)}`;
                        that.setState({time: str});
                    }, 1000);
                } else {
         
                    var end=moment(result.date,'DD-MM-YYYY HH:mm:ss').add(-1,'minutes')
                   
                    that.itv = setInterval(function () {
                      const timeLeft = moment(end.diff(moment())).utc();           
                      const formatted = timeLeft.format('HH:mm:ss');
                       if(end.diff(moment())<=0){
                        that.handleRefresh();
                        return;
                       }
                      that.setState({ time: formatted });
                    }, 1000);
                  }
            }
        });

    }

    componentDidMount() {
        this.handleRefresh();
    }

    componentWillUnmount() {
        clearInterval(this.itv);
    }

    render() {
        return (
            <Link to={this.props.productId === 7 ? "/dien-toan" : `/lottery/${this.props.lottery}`} className="link-unset">
                <div className="menu-card">
                    <div className="menu-card__img py-1">
                        <img src={logo[this.props.lottery]} className="p-2" style={{width: "100%"}} alt="icon"/>
                    </div>
                    <div className="menu-card__content py-1">
                        <div
                            className="font-weight-bold"
                            style={{fontSize: "0.875rem"}}
                        >
                            {
                                this.props.productId !== 6 ?
                                    this.props.productId === 7 ?
                                        "Hàng ngày" :
                                        `Ngày quay: ${this.state.nextDate}`
                                    :

                                    "Cả tuần, 5 phút 1 kỳ quay"
                            }
                        </div>
                        <div
                            style={{color: this.props.color, fontSize: "1.2rem"}}
                            className="font-weight-bold"
                        >
                            {this.props.content}
                        </div>
                        <div style={{fontSize: "0.875rem"}}>
                            Thời gian còn: {this.state.time}
                        </div>
                    </div>
                    {/* <div className="menu-card__caret" style={{backgroundColor: this.props.color}}>
                        >
                    </div> */}
                </div>
            </Link>
        );
    }
}

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            content: {
                mega645: "--.---.---.---- ₫",
                power655: "---.---.---.--- ₫",
                max3d: "x100 lần",
                max3d_plus: "x100.000 lần",
                max4d: "x1500 lần",
                vietlottkeno: "2.000.000.000 ₫",
                235: "x70, x1000, x40.000 lần",
                kenobao: "Chơi bao keno",
                max3d_pro:"2 Tỷ VND"
              },
        }
    }


    componentDidMount() {
        api.getAllResult((err, result) => {
            if (result) {
                let content = JSON.parse(JSON.stringify(this.state.content));
                content = {
                    ...content,
                    ...result
                };
                this.setState({content})
            }
        })
    }

    render() {
        return (
            <div className="home-page">
                <div style={{height: "100%", overflowY: "auto"}}>
                   
                    <div className="py-2">
                        {
                            menuCardData.map(({productId, lottery, color}, index) => (
                                <MenuCard
                                    key={index}
                                    lottery={lottery}
                                    productId={productId}
                                    color={color}
                                    content={this.state.content[lottery]}
                                />
                            ))
                        }
                    </div>
                    {/* <div className="px-2 pb-4">
                        <a href="https://vietlott.vn/vi/choi/mega-6-45/cach-choi" target="_blank" rel="nofollow noopener noreferrer">
                            <div className="guide-button p-2 hover-pointer">
                                Hướng dẫn chơi
                            </div>
                        </a>
                    </div> */}
                                   
                </div>

                <div className="home-page__footer py-2 d-flex">
                    <div className="w-50 text-center">
                        <Link to={"/"} className="link-unset">
                            <div>
                                <Grid color={window.location.pathname === "/" ? "#169714" : "grey"}/>
                            </div>
                            <div>
                                <span
                                    style={{
                                        fontSize: "0.875rem",
                                        color: window.location.pathname === "/" ? "#169714" : "grey"
                                    }}
                                >Đặt vé</span>
                            </div>
                        </Link>
                    </div>
                    <div className="w-50 text-center">
                        <Link to={"/truc-tiep-keno"} className="link-unset">
                            <div>
                            <div class="livenow">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>                  
                            </div>
                            </div>
                            <div>
                                <span
                                    style={{
                                        fontSize: "0.875rem",
                                        color: window.location.pathname === "/truc-tiep-keno" ? "#169714" : "grey"
                                    }}
                                >Trực tiếp keno</span>
                            </div>
                        </Link>
                    </div>
                    <div className="w-50 text-center">
                        <Link to={"/result"} className="link-unset">
                            <div>
                                <HardDrive color={window.location.pathname === "/result" ? "#169714" : "grey"}/>
                            </div>
                            <div>
                                <span
                                    style={{
                                        fontSize: "0.875rem",
                                        color: window.location.pathname === "/result" ? "#169714" : "grey"
                                    }}
                                >Kết quả</span>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;
