import React from "react";

class Landing extends React.Component {
    render() {
        return (
            <div className="landing-page">
                {this.props.children}
            </div>
        )
    }
}

export default Landing;
