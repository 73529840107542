import path, {root} from "./path";


const config = {
    path: path
};

export default config;

export {path, root}
