import React from "react";
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import {
    landing as landingRoutes,
    dashboard as dashboardRoutes,
    page as pageRoutes
} from "./index";

import DashboardLayout from "../layouts/Dashboard";
import LandingLayout from "../layouts/Landing";
import AuthLayout from "../layouts/Auth";

import LoadingPage from "../components/LoadingPage";

import {userChange, userClear} from "../redux/actions/userAction";
import {changeLotteryConfig} from "../redux/actions/lotteryConfigActions";
import {connect} from "react-redux";

import ScrollToTop from "../components/ScrollToTop";
import apiMyAccount from "../api/MyAccount";
import utils from "../utils";
import firebase from "firebase";
import apiOrder from "../api/Order";

import axios from "axios";

const childRoutes = (Layout, routes, options) =>
    routes.map(({children, path: root_path, component: Component}, index) =>
        children ? (
            // Route item with children
            children.map(({path, component: Component}, index) => (
                <Route
                    key={index}
                    path={root_path + path}
                    exact
                    render={props => (
                        <Layout options={options}>
                            <Component {...props} />
                        </Layout>
                    )}
                />
            ))
        ) : (
            // Route item without children
            <Route
                key={index}
                path={root_path}
                exact
                render={props => (
                    <Layout options={options}>
                        <Component {...props} />
                    </Layout>
                )}
            />
        )
    );

class Routes extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: true
        }
    }


    componentDidMount() {

        axios.get("/tsconfig.json")
            .then(res => {
                if(res.data.isMaintenance) {
                    utils.confirm(res.data.contentMaintenance, (check) => {
                        window.location.reload()
                    })
                }
            })
            .catch(e => {

            });

        this.isLogin = !utils.isEmpty(this.props.user.authToken) && this.props.user.isActive;
        if(this.isLogin) {
            apiMyAccount.getUserInfoSomeKey((err, result) => {
                if(err) {

                }else  {
                    this.props.userChange(result);
                }
            })
        }
        if (firebase.messaging.isSupported()) {
            if (this.isLogin) {
                apiMyAccount.setFcmToken();
            }
        }

        apiOrder.getConfig((err, result) => {
            if(result) {
                this.props.changeLotteryConfig(result);
            }
        })
    }

    render() {
        if (!this.state.isLoaded) {
            return (
                <LoadingPage/>
            )
        }
        const isLogin = false;
        return (
            <Router>
                <ScrollToTop>
                    <Switch>
                        {childRoutes(LandingLayout, landingRoutes)}
                        {childRoutes(AuthLayout, pageRoutes)}
                        {childRoutes(DashboardLayout, dashboardRoutes)}
                        <Route
                            render={() => (
                                <Redirect to={isLogin ? "/recently" : "/auth/sign-in"}/>
                            )}
                        />
                    </Switch>
                </ScrollToTop>
            </Router>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps, {userClear, userChange, changeLotteryConfig})(Routes);
