const path = require('../config').path;

const axios = require('axios');

const type = ['', 'mega645', 'power655', 'max4d', 'max3d', 'max3d', 'keno','235','234','6x36','thantai','123','max3d_pro'];

function getProduct(productId, callback){
    axios.get(path.product[type[productId]], {
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then(res => {
            let term = res.data.term;

            if(productId !== 6) {
                if(productId<6 || productId===12)
                for (let i = 0; i < 6; i++) {
                    term[i].date = term[i].date.formatDateTime('-dmy', '/dmy');
                }
                else{
                    for (let i = 0; i<term.length; i++) {
                        term[i].date = term[i].date.formatDateTime('-dmy', '/dmy');
                    }
                }
            }
            res.data.term=term;
            return callback(null, res.data);
        })
        .catch(error => {
            if (callback) {
                return callback(error);
            }
        });
}

function getTerm(productId, callback) {
    axios.get(path.product[type[productId]], {
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then(res => {
            let term = res.data.term;

            if(productId !== 6) {
                if(productId<6 || productId===12)
                for (let i = 0; i < term.length; i++) {
                    term[i].date = term[i].date.formatDateTime('-dmy', '/dmy');
                }
                else{
                    term[0].date = term[0].date.formatDateTime('-dmy', '/dmy');
                }
            }
            return callback(null, term);
        })
        .catch(error => {
            if (callback) {
                return callback(error);
            }
        });
}

module.exports = {
    getTerm: getTerm,
    getProduct
};
