import * as types from "../constants";

const initialState = {
    "fullName": "",
    "gender": "",
    "phoneNumber": "",
    "address": "",
    "idCardNumber": "",
    "walletId": "",
    "orderShoppingCartId": "",
    "cityId": "",
    "districtId": "",
    "email": "",
    "balance": "",
    "availableBalance": "",
    "isActive": "",
    "authToken": "",
    "winingBalance":"",
    "isSendMail": "",
};

export default function (state = initialState, action) {
    switch (action.type) {
        case types.USER_CHANGE:
            return action.user;
        case types.USER_CLEAR:
            return  initialState;
        default:
            return state;
    }
}
