import * as type from '../constants';

export const lotterySet = (product) => ({
    type: type.LOTTERY_SET,
    product
});

export const lotteryChangeNumber = (number) => ({
    type: type.LOTTERY_CHANGE_NUMBER,
    number
});

export const lotteryChangePrice = (price) => ({
    type: type.LOTTERY_CHANGE_PRICE,
    price
});

export const lotteryChange = (change) => ({
    type: type.LOTTERY_CHANGE,
    change
});
