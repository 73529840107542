import * as types from "../constants";

const initialState = {
    EXPRESS_DELIVERY_FEE: 100,
    NORMAL_DELIVERY_FEE: 50,
    KENO_TIME_LIMIT: 1,
    GLOBAL_TIME_LIMIT: "17:30:30",
    WITHDRAWAL_ONLINE_FEE: 0,
    MINIMUM_AMOUNT_PER_TIME: 100,
    MAXIMUM_AMOUNT_PER_TIME: 20000,
    OPEN_TIME: "08:00:00",
    CLOSE_TIME: "21:57:59",
};

export default function (state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_LOTTERY_CONFIG:
            return {
                ...state,
                ...action.config
            };
        default:
            return state;
    }
}
