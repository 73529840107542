import React, {Component} from 'react';

import {
    Row, Col, Progress, Label, Input, FormGroup
} from "reactstrap";

import {Tabs, Tab, TabPanel, TabList} from "react-tabs";

import NumberCircle from "../../components/Vietluck/NumberCircle";

import ButtonLoading from "../../components/FworkTag/ButtonLoading";

import LoadingPage from "../../components/LoadingPage";

import apiResult from "../../api/Result";

import moment from "moment";
/* eslint-disable */

class MainAnalytic extends Component {

    constructor(props) {
        super(props);
        this.productId = window.location.hash === "#2" ? 1 : window.location.hash === "#3" ? 2 : 6 ;
        this.state = {
            fromdate: moment().subtract(7, "days").format("YYYY-MM-DD"),
            todate: moment().format("YYYY-MM-DD"),
            isLoaded: false,
            sortType: 1,
            period: 0
        }
    }

    componentDidMount() {
        this.handleSearch()
    }

    handleSearch() {
        if(this.state.isLoading) return;
        this.setState({isLoading: true});
        if(this.productId === 6) {
            if(this.state.period != 0) {
                apiResult.statisticalKenoByTerm(this.state.period, (err, result) => {
                    if(err){

                    } else {
                        this.setState({result: result, isLoaded: true, isLoading: false})
                    }
                });
                return;
            }
        }
        apiResult.statistical(
            this.productId,
            [this.state.fromdate.formatDateTime("-ymd", "-dmy"), this.state.todate.formatDateTime("/ymd", "-dmy")], (err, result) => {
                if (err) {

                } else {
                    this.setState({result: result, isLoaded: true, isLoading: false})
                }
            })
    }

    handleChange(event){
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleChangePeriod(event){
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleChangeTab(id) {
        switch (id) {
            case 0:
                this.productId = 6;
                window.location.hash = 1;
                break;
            case 1:
                this.productId = 1;
                window.location.hash = 2;
                break;
            case 2:
                this.productId = 2;
                window.location.hash = 3;
                break;
        }
        this.setState({period: 0});
        this.handleSearch()
    }

    renderContent () {
        let bo_so;
        if(this.state.sortType==1) {
            bo_so = this.state.result.bo_so_giam_dan;
        } else if(this.state.sortType==2) {
            bo_so = this.state.result.bo_so_tang_dan;
        } else {
            bo_so = this.state.result.bo_so;
        }
        return (
            <>
                <div className="pb-2 border-bottom mb-2">
                    <Row>
                        <Col className="col-6">
                            <FormGroup>
                                <Label>Từ ngày</Label>
                                <Input type="date" style={{width: "95%"}} value={this.state.fromdate} name="fromdate" onChange={this.handleChange.bind(this)}/>
                            </FormGroup>
                        </Col>
                        <Col className="col-6">
                            <FormGroup>
                                <Label>Đến ngày</Label>
                                <Input type="date" style={{width: "95%"}} value={this.state.todate} name="todate" onChange={this.handleChange.bind(this)}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    {
                        this.productId === 6 &&
                            <Row className="mb-2">
                                <Input type="select" name="period" value={this.state.period} onChange={this.handleChangePeriod.bind(this)}>
                                    <option value={0}>Thống kê theo ngày</option>
                                    <option value={10}>10 kỳ gần nhất</option>
                                    <option value={20}>20 kỳ gần nhất</option>
                                    <option value={30}>30 kỳ gần nhất</option>
                                    <option value={50}>50 kỳ gần nhất</option>
                                </Input>
                            </Row>
                    }
                    <Row className="justify-content-center">
                        <ButtonLoading
                            loading={this.state.isLoading}
                            title="Thống kê"
                            onClick={this.handleSearch.bind(this)}
                        />
                    </Row>
                </div>
                <div>
                    <Row className="justify-content-center">
                        <span className="font-weight-bold">Xuất hiện ít nhất</span>
                    </Row>
                    <Row className="justify-content-center">
                        {
                            this.state.result.xuat_hien_it_nhat.map((number, index) => (
                                <NumberCircle
                                    key={index}
                                    title={number}
                                    className="mr-1"
                                />
                            ))
                        }
                    </Row>
                </div>
                <div className="mt-2 border-bottom pb-2">
                    <Row className="justify-content-center">
                        <span className="font-weight-bold">Xuất hiện nhiều nhất</span>
                    </Row>
                    <Row className="justify-content-center">
                        {
                            this.state.result.xuat_hien_nhieu_nhat.map((number, index) => (
                                <NumberCircle
                                    key={index}
                                    title={number}
                                    className="mr-1"
                                />
                            ))
                        }
                    </Row>
                </div>
                <div className="mt-3">
                    <Row className="mb-2">
                        <Input type={"select"} name="sortType" value={this.state.sortType} onChange={this.handleChange.bind(this)}>
                            <option value={3}>Theo thứ tự của bóng</option>
                            <option value={1}>Xuất hiện giảm dần</option>
                            <option value={2}>Xuất hiện tăng dần</option>
                        </Input>
                    </Row>
                    {
                        bo_so.map(({number, count}, index) => (
                            <Row key={index} className="mt-1">
                                <Col className="col-2">
                                    <Row className="h-100 justify-content-center align-items-center">
                                        <NumberCircle
                                            title={number}
                                        />
                                    </Row>
                                </Col>
                                <Col>
                                    <Row className="align-items-center h-100 justify-content-center">
                                        <Progress
                                            value={(count / this.state.result.total * 100) + (this.productId === 6 ? 20 : 0)}
                                            style={{height: "10px", width: "100%"}}
                                            color="danger"
                                        />
                                    </Row>
                                </Col>
                                <Col className="col-2">
                                    <Row className="h-100 justify-content-center">
                                        {count}
                                    </Row>
                                </Col>
                            </Row>
                        ))
                    }
                </div>
            </>
        )
    }

    render() {
        if (!this.state.isLoaded) {
            return (
                <LoadingPage/>
            )
        }

        return (
            <div className="bootstrap-reset p-2">
                <Tabs selectedIndex={this.productId === 6 ? 0 : this.productId} onSelect={this.handleChangeTab.bind(this)}>
                    <TabList>
                        <Tab>Keno</Tab>
                        <Tab>Mega</Tab>
                        <Tab>Power</Tab>
                    </TabList>
                    <TabPanel>
                        {
                            this.renderContent()
                        }
                    </TabPanel>
                    <TabPanel>
                        {
                            this.renderContent()
                        }
                    </TabPanel>
                    <TabPanel>
                        {
                            this.renderContent()
                        }
                    </TabPanel>
                </Tabs>
            </div>
        );
    }
}

export default MainAnalytic;
