import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from "react";
import ReactDOM from "react-dom";

import App from "./App";

import "./utils/ArrayPrototype";
import "./utils/ObjectPrototype";
import "./utils/StringPrototype";

// import loglevel from "loglevel";

import {I18nextProvider} from 'react-i18next';
import i18n from './i18n';

// loglevel.setLevel(process.env[`REACT_APP_${process.env.REACT_APP_STAGE}_LOGLEVEL`]);

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <App/>
    </I18nextProvider>,
    document.getElementById("root"));

if ("serviceWorker" in navigator) {
    navigator.serviceWorker
        .register("firebase-messaging-sw.js")
        .then(function(registration) {
            // log.info("Registration successful, scope is:", registration.scope);
            // console.log("Registration successful")
        })
        .catch(function(err) {
            // console.log("Service worker registration failed")
            // log.info("Service worker registration failed, error:", err);
        });
}

