import {
    ADD_TO_CART,
    REMOVE_FROM_CART,
    REMOVE_ALL_CART,
    LOAD_CART,
    REMOVE_KENO_CART,
    REMOVE_OTHER_CART
} from "../constants";

const initialState = {
    cart: [],
};

export default function (state = initialState, action) {

    let copy_state = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case LOAD_CART:
            copy_state.cart = action.cart;
            return copy_state;
        case ADD_TO_CART:
            action.product.id = String.randomString();
            copy_state.cart.push(action.product);
            return copy_state;
        case REMOVE_FROM_CART:
            let index = copy_state.cart.find(dt => dt.id===action.id);
            index = copy_state.cart.indexOf(index);
            if(index !== -1){
                copy_state.cart.splice(index, 1);
            }
            return copy_state;
        case REMOVE_KENO_CART:
            copy_state.cart = copy_state.cart.filter(cp => cp.productId!==6);
            return copy_state;
        case REMOVE_OTHER_CART:
            copy_state.cart = copy_state.cart.filter(cp => cp.productId===6);
            return copy_state;
        case REMOVE_ALL_CART:
            copy_state.cart = [];
            return copy_state;
        default:
            return state;
    }
}
