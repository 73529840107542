/* eslint-disable */

const moment = require("moment/moment");

const listStrFormat = {
    "/dmy": "DD/MM/YYYY",
    "-dmy": "DD-MM-YYYY",
    "/ymd": "YYYY/MM/DD",
    "-ymd": "YYYY-MM-DD"
};

String.prototype.formatDateTime = function (inputFormat="-dmy", outputFormat="/dmy") {
    return moment(this, listStrFormat[inputFormat]).format(listStrFormat[outputFormat]);
};

String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.substring(1);
};

String.randomString = function (length) {
    if (length === undefined)
        length = 20;
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

String.prototype.getMoneyFormat = function () {
    let fl = parseFloat(this);
    let ff = Math.floor(fl);

    let fb = ff === fl ? "000" : (fl - ff).toFixed(3).split(".")[1];
    ff = ff.getMoneyFormat();
    return ff;
};

