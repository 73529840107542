import React, {Component} from 'react';

import {Input} from "reactstrap";
import {isEmpty} from "../../utils";

class InputCurrency extends Component {
    constructor(props) {
        super(props);
        this.state = {
            money: isEmpty(this.props.default) ? "" : this.props.default
        }
    }

    handleChange(event){
        // this.setState({money: event.target.value})
        if(isEmpty(event.target.value)) {
            this.setState({money: ""});
        } else {
            if(!/^[0-9]/.test(event.target.value))
                return;
            let value = event.target.value.replace(/\./g, "");
            if(parseInt(value) > 999999999999999) {
                return;
            }
            value = value.getMoneyFormat();
            this.setState({money: value})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.money !== this.state.money) {
            this.props.onChange(this.state.money.toString().replace(/\./g, ""))
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return(
            nextState.money !== this.state.money
        )
    }

    render() {
        return (
            <Input
                type="numeric"
                name="money"
                value={this.state.money}
                onChange={this.handleChange.bind(this)}
                className={this.props.className ? this.props.className : ""}
                style={this.props.style ? this.props.style : {}}
                placeholder="0"
            />
        );
    }
}

export default InputCurrency;
