import {toastr} from "react-redux-toastr";

export default function PushNotify(type, status, title, message) {
    const options = {
        timeOut: 5000,
        showCloseButton: true,
        progressBar: true,
        position: 'bottom-left'
    };
    const toastrInstance =
        status === "info"
            ? toastr.info
            : status === "warning"
            ? toastr.warning
            : status === "error"
                ? toastr.error
                : toastr.success;

    message = (typeof message === "object") ? JSON.stringify(message) : message;

    toastrInstance(
        title,
        message,
        options
    );
    // message = (typeof message==="object") ? JSON.stringify(message) : message;
}

