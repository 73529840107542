import React from "react";
import {Row} from "reactstrap";

export default class ListItem extends React.Component {
    render() {
        return (
            <Row className="list-item bootstrap-reset p-0 m-0 border-bottom p-1">
                <div className="left-component">
                    <img src={this.props.avatar} alt={"img"}/>
                </div>
                <div className="content-component">
                    <Row>
                        <span>{this.props.title}</span>
                    </Row>
                    <Row>
                        <span>{this.props.subtitle}</span>
                    </Row>
                </div>
                <div className="right-component">
                    <div className="d-flex align-items-center">
                        <div className="w-100">
                            <Row>
                                <span>{this.props.rightTitle}</span>
                            </Row>
                            {
                                this.props.rightSubTitle &&
                                <Row style={this.props.rightSubTitleStyle ? this.props.rightSubTitleStyle : {}}>
                                    <span style={{fontSize: "0.85rem"}}>{this.props.rightSubTitle}</span>
                                </Row>

                            }
                        </div>
                        <div>
                            <span className="text-primary">></span>
                        </div>
                    </div>
                </div>
            </Row>
        )
    }
}
