import rootApi from "./rootApi";
import ErrorMessage from "../utils/ErrorMessage";

const path = require("../config").path;

function checkPrice(data,callback){
    rootApi.post(path.order.checkPrice,data).then(res => {
        return callback(null, res.data);
    })
    .catch(error => {
        ErrorMessage(error);
        return callback(error);
    })
}
function createOrder(data, callback) {
   
    rootApi.post(path.order.createOrder, data)
        .then(res => {
            return callback(null, res.data);
        })
        .catch(error => {
            ErrorMessage(error);
            return callback(error);
        })
}

function getConfig(callback) {
    rootApi.get(path.config.config, {})
        .then(res => {
            return callback(null, res.data);
        })
        .catch(error => {
            ErrorMessage(error);
            return callback(error, null)
        })
}

export default {
    createOrder,
    getConfig,
    checkPrice
};
