import React, {Component} from 'react';
import moment from "moment";

import {
    Grid, HardDrive
} from "react-feather"

import colors from "../../utils/colors";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import {Link} from "react-router-dom";

import utils from "../../utils";

import api from "../../api/Result";

import {Helmet} from "react-helmet";
var Carousel = require('react-responsive-carousel').Carousel;

const menuCardData = [
    {
        productId: 7,
        lottery: "235",
        name:'Hàng ngày - Lô tô 2,3,5 số',
        color: colors.facebookColor
    },
    {
        productId: 8,
        lottery: '234',
        name:'Hàng ngày',
        color: colors.facebookColor,
    },
    
    
    {
        productId: 9,
        lottery: '6x36',
        name:'Thứ tư, thứ bảy hàng tuần',
        color: colors.facebookColor,
    },
    {
        productId: 10,
        lottery: 'than-tai',
        name:'Hàng ngày',
        color: colors.facebookColor,
    },
    {
        productId: 11,
        lottery: '123',
        name:'Hàng ngày',
        color: colors.facebookColor,
    },
   
];

const logo = {    
    '235': require("../../assets/images/logo/dien-toan.png")
};

class MenuCard extends React.Component {
    constructor(props) {
        super(props);
        this.itv = null;
        this.countDownDate = new Date(utils.generateCountDownDate(6)).getTime();
        this.state = {
            date: moment().format('DD/MM/YYYY HH:MM:SS'),
            time: '00:00:00:00',
            nextDate: '01/01/2020',
            nextDateTime: '01/01/2020 18:00:00',
        };
    }

    timeGenerate() {
        return this.state.time;
    }

    handleRefresh() {       
        api.getNearestTerm(this.props.productId, (err, result) => {
            if (err) {

            } else {
                const that = this;
                if (this.itv) {
                    clearInterval(this.itv);
                }
                if (this.props.productId !== 6) {
                    let nextDate = result.date;
                    nextDate = moment(nextDate, 'DD/MM/YYYY HH:mm:ss');                   
                    this.setState({
                        nextDateTime: nextDate.format('DD/MM/YYYY HH:MM:SS'),
                        nextDate: nextDate.format('DD/MM/YYYY'),
                    });
                    that.itv = setInterval(function () {
                        let countDown = (nextDate - moment()) / 1000;
                        let day = Math.floor(countDown / 60 / 60 / 24);
                        let hour = 0;
                        let minute = 0;
                        let second = 0;
                        hour = Math.floor((countDown - day * 24 * 60 * 60) / 60 / 60);
                        minute = Math.floor((countDown - day * 24 * 60 * 60 - hour * 60 * 60) / 60);
                        second = Math.floor((countDown - day * 24 * 60 * 60 - hour * 60 * 60 - minute * 60));
                        if ((day === 0 && hour === 0 && minute === 0 && second === 0) || day < 0) {
                            clearInterval(that.itv);
                            that.handleRefresh();
                            return;
                        }
                        let str = `${utils.formatValue(day)}:${utils.formatValue(hour)}:${utils.formatValue(minute)}:${utils.formatValue(second)}`;
                        that.setState({time: str});
                    }, 1000);
                } else {
                    that.itv = setInterval(function () {
                        let now = new Date().getTime();
                        let distance = that.countDownDate - now;
                        if (distance < 0) {
                            that.countDownDate = new Date(utils.generateCountDownDate(6)).getTime();
                            return;
                        }

                        let day = Math.floor(distance / (1000 * 60 * 60 * 24));
                        let hour = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                        let minute = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                        let second = Math.floor((distance % (1000 * 60)) / 1000);

                        let str = `${utils.formatValue(day)}:${utils.formatValue(hour)}:${utils.formatValue(minute)}:${utils.formatValue(second)}`;
                        that.setState({time: str});
                    }, 1000);

                }
            }
        });

    }

    componentDidMount() {
        this.handleRefresh();
    }

    componentWillUnmount() {
        clearInterval(this.itv);
    }
    isHot(){
        if(this.props.productId===7)
            return true;
        return false;
    }
    isToday() {
        if (this.props.productId === 6 ) {
            return false;
        }
        
        const dt = moment(this.state.nextDate, 'DD/MM/YYYY');
        const today = moment();
        if (dt.get('days') === today.get('days') && dt.get('months') === today.get('months')) {
            return true;
        }
        return false;
    }
    render() {
        return (
            <Link to={this.props.productId<12 ? `/lottery/${this.props.lottery}` : '#'} className="link-unset">
                <div className="menu-card">
                    <div className="menu-card__img py-1">
                        <img src={logo["235"]} className="p-2" style={{width: "100%"}} alt="icon"/>
                        {
                            this.isHot() &&
                            <div
                                style={{
                                    position: 'absolute',
                                    top: -25,
                                    left: -25                                
                                }}
                            >
                                 <img src={require('../../assets/images/logo/hot.png')} className="p-2" style={{width: 70}} alt="icon"/>
                            </div>
                        }
                    </div>
                    <div className="menu-card__content py-1">
                        <div
                            className="font-weight-bold"
                            style={{fontSize: "0.875rem"}}
                        >
                            {menuCardData.find(p=>p.productId===this.props.productId).name}
                        </div>
                        <div
                            style={{color: this.props.color, fontSize: "1.2rem"}}
                            className="font-weight-bold"
                        >
                            {this.props.content}
                        </div>
                        <div style={{fontSize: "0.875rem"}}>
                            Thời gian còn: {this.props.productId >11?"00:00:00": this.state.time}
                        </div>
                        {
                            this.isToday() &&
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    fontSize: 10,
                                    padding: 3,
                                    color: 'white',
                                    backgroundColor: 'red',
                                    borderRadius: 5,
                                    marginTop: 5,
                                    marginRight: 5,
                                }}
                            >
                                Hôm nay xổ
                            </div>
                        }
                    </div>
                    <div className="menu-card__caret" style={{backgroundColor: this.props.color}}>
                        >
                    </div>
                </div>
            </Link>
        );
    }
}

class KTMB extends Component {

    constructor(props) {
        super(props);
        this.state = {
            content: {                
                "dien-toan": "Xổ số Kiến thiết Miền Bắc",
                "235":"x70, x420, x20.000 lần",
                "234":"Lô tô 2, 3, 4 cặp số",
                "123":"Điện toán 123"               ,
                "6x36":"Điện toán 6x36",
                "than-tai":"Thần tài 4"
            },
            seo:{}
        }
    }


    async componentDidMount() {
        api.getAllResult((err, result) => {
            if (result) {
                let content = JSON.parse(JSON.stringify(this.state.content));
                content = {
                    ...content,
                    ...result
                };
                this.setState({content})
            }
        });
        var seo= await utils.fetchSeo('ktmb.json');
        this.setState({seo:seo});
    }

    render() {
        return (
            <div className="home-page">
            {/* <Helmet>
                    <meta charSet="utf-8" />
                    <title>{this.state.seo.title}</title>
                    {
                        this.state.seo.canonical===''?null: <link rel="canonical" href={this.state.seo.canonical} />
                    }
                   
                    <meta
                        name="keywords"
                        content={this.state.seo.keyword}
                    />
                    <meta
                    name="description"
                    content={this.state.seo.description}
                    />
                    <meta property="og:url" content={this.state.seo.url}/>                    
                    <meta property="og:type" content="website"/>
                    <meta property="og:title" content={this.state.seo.title}/>
                    <meta property="og:description" content={this.state.seo.description}/>                    
                    <meta property="og:image" content={this.state.seo.thumb}/>

                </Helmet> */}
                <div style={{height: "100%", overflowY: "auto"}}>
                   
                    <div className="py-2">
                        {
                            menuCardData.map(({productId, lottery, color}, index) => (
                                <MenuCard
                                    key={index}
                                    lottery={lottery}
                                    productId={productId}
                                    color={color}
                                    content={this.state.content[lottery]}
                                />
                            ))
                        }
                    </div>
                    <div className="px-2 pb-4">
                        <a target="_blank" rel="nofollow noopener noreferrer">
                            <div className="guide-button p-2 hover-pointer">
                                Hướng dẫn chơi
                            </div>
                        </a>
                    </div>
                    {/* <div className="p-2 text-white" style={{fontSize: "0.875rem"}}>
                        <p>Dịch vụ đặt mua <a href="https://vietluck.vn/">Vietlott</a> online tr&ecirc;n Mobile&nbsp;số
                          1 Việt Nam. Thao t&aacute;c đơn giản mua
                          v&eacute; số <a href="https://vietluck.vn/lottery/power655">Power</a> (vietlott 6/45), Max
                          3D, Max 4D, <a href="https://vietluck.vn/lottery/mega645">Mega</a> (vietlott
                          6/55),&nbsp;Keno &amp; xem kết quả&nbsp;xổ số Vietlotl, kq Vietlot&nbsp;trực tuyến uy
                          t&iacute;n của c&ocirc;ng ty VietLuck ngay tr&ecirc;n điện thoại.
                        </p>
                        
                        <p>Phát triển bởi Công ty cổ phần dịch vụ VietLuck Việt Nam. 74 Quán Sứ, Trần Hưng Đạo, Hoàn Kiếm, Hà Nội
                        </p> 
                    </div> */}
                </div>

                <div className="home-page__footer py-2 d-flex">
                    <div className="w-50 text-center">
                        <Link to={"/"} className="link-unset">
                            <div>
                                <Grid color={window.location.pathname === "/" ? "red" : "grey"}/>
                            </div>
                            <div>
                                <span
                                    style={{
                                        fontSize: "0.875rem",
                                        color: window.location.pathname === "/" ? "red" : "grey"
                                    }}
                                >Đặt vé</span>
                            </div>
                        </Link>
                    </div>
                    <div className="w-50 text-center">
                        <Link to={"/result"} className="link-unset">
                            <div>
                                <HardDrive color={window.location.pathname === "/result" ? "red" : "grey"}/>
                            </div>
                            <div>
                                <span
                                    style={{
                                        fontSize: "0.875rem",
                                        color: window.location.pathname === "/result" ? "red" : "grey"
                                    }}
                                >Kết quả</span>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default KTMB;
