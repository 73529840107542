import * as types from "../constants";

/* eslint eqeqeq: "off" */

const initialState = {
    "value": [{number: [], price: 0}],
    "productId": 1,
    "productOptionId": 1,
    "productOptionKenoTypeId": 0,
    "numberSelect": 6,
    "startNumber": 1,
    "endNumber": 1,
    "productOptionArray": [],
    "productOptionKenoTypeArray": [],
    "termArray": [],
    "term": []
};

const productDataset = require("../../utils/productData").productDataset;

function getNumberSelect(productId, productOptionId, options = {}) {
    let productData = productDataset.find(dt => dt.id == productId);
    let productOption;
    if (options.kenoType) {
        if (productOptionId) {
            productOption = productData.productOptionKenoTypeArray.find(dt => dt.id == productOptionId);
        } else {
            productOption = productData.productOptionArray.find(dt => dt.id == options.productOptionId);
        }
    } else {
        productOption = productData.productOptionArray.find(dt => dt.id == productOptionId);
    }

    return productOption.select;
}

function getStartNumber(productId, productOptionId, options = {}) {
    let productData = productDataset.find(dt => dt.id == productId);
    let productOption;
    if (options.kenoType) {
        productOption = productData.productOptionKenoTypeArray.find(dt => dt.id == productOptionId);
    } else {
        productOption = productData.productOptionArray.find(dt => dt.id == productOptionId);
    }

    return productOption.startNumber;
}

function getEndNumber(productId, productOptionId, options = {}) {
    let productData = productDataset.find(dt => dt.id == productId);
    let productOption;
    if (options.kenoType) {
        productOption = productData.productOptionKenoTypeArray.find(dt => dt.id == productOptionId);
    } else {
        productOption = productData.productOptionArray.find(dt => dt.id == productOptionId);
    }

    return productOption.endNumber;
}

function getPriceForProductOptionId(productId, productOptionId) {
    let productData = productDataset.find(dt => dt.id == productId);
    let productOption = productData.productOptionArray.find(dt => dt.id == productOptionId);
    return productOption.price;
}

function getPriceArray(productId) {
    let productData = productDataset.find(dt => dt.id == productId);
    let tmp = productData.priceArray;
    let priceArray = [];
    tmp.forEach(dt => {
        priceArray.push({id: dt, price: dt + " K"})
    });
    return priceArray;
}

function getProductOptionKenoTypeArray(productOptionId) {
    let productOptionKenoTypeArray = [
        {
            "id": 0,
            "name": "Vé thường",
            "select": 1,
            "startNumber": 1,
            "endNumber": 80,
            "price": 0
        }
    ]

    let data = productDataset[5].productOptionKenoTypeArray;
    let index = productOptionId - 70 - 1;
    for (let i = index; i < data.length; i++) {
        productOptionKenoTypeArray.push(data[i]);
    }
    return productOptionKenoTypeArray;
}

export default function (state = initialState, action) {
    switch (action.type) {
        case types.LOTTERY_CHANGE:
            let numTicket=action.change.productId>6?5:6;
            let copy_change = JSON.parse(JSON.stringify(state));
            Object.keys(action.change).forEach(key => {
                if (key === "productOptionId") {
                    if (copy_change.productId === 6 && action.change.productOptionId !== 82) {
                        copy_change.productOptionKenoTypeArray = getProductOptionKenoTypeArray(action.change.productOptionId);
                        copy_change.productOptionKenoTypeId = copy_change.productOptionKenoTypeArray[0].id;
                    }
                    let numTicket=copy_change.productId>6?5:6;
                    copy_change.value = Array(numTicket).fill({
                        "number": [],
                        "price": [3, 4, 5, 6,7,8,10,11,12].includes(state.productId) ? getPriceArray(state.productId)[1].id : getPriceForProductOptionId(state.productId, action.change.productOptionId)
                    });
                    if(numTicket===5){
                        copy_change.value = Array(numTicket).fill({
                            "number": [],
                            "price": [7,8,10,11,12].includes(state.productId) ? getPriceArray(state.productId)[0].id : getPriceForProductOptionId(state.productId, action.change.productOptionId)
                        });
                    }
                    copy_change.numberSelect = getNumberSelect(state.productId, action.change.productOptionId);
                    copy_change.startNumber = getStartNumber(state.productId, action.change.productOptionId);
                    copy_change.endNumber = getEndNumber(state.productId, action.change.productOptionId);
                    //gioi han loto 5 so
                    if(action.change.productOptionId===85){
                        copy_change.priceArray=getPriceArray(state.productId).filter(x=>x.id<=200);
                    }
                    else{
                        copy_change.priceArray=getPriceArray(state.productId)
                    }
                  
                    if(action.change.isChangeKenoBao){
                        copy_change.numberSelect = getNumberSelect(state.productId, copy_change.productOptionKenoTypeArray[1].id, {
                            kenoType: true,
                            productOptionId: state.productOptionId
                        });
                        copy_change.productOptionKenoTypeId=copy_change.productOptionKenoTypeArray[1].id;
                    }
                }
                if (key === "productOptionKenoTypeId") {
                    if (action.change.productOptionKenoTypeId) {
                        copy_change.numberSelect = getNumberSelect(state.productId, action.change.productOptionKenoTypeId, {
                            kenoType: true,
                            productOptionId: state.productOptionId
                        });
                    } else {
                        copy_change.numberSelect = getNumberSelect(state.productId, state.productOptionId);
                    }

                    copy_change.value = Array(6).fill({
                        "number": [],
                        "price": [3, 4, 5, 6,7,8,9,10,11,12].includes(state.productId) ? getPriceArray(state.productId)[1].id : getPriceForProductOptionId(state.productId, state.productOptionId)
                    });
                }
                copy_change[key] = action.change[key]
            });
            return copy_change;
        case types.LOTTERY_CHANGE_NUMBER:
            let copy_number = JSON.parse(JSON.stringify(state));
            copy_number.number = action.number;
            return copy_number;
        case types.LOTTERY_SET:
            let lottery_set_productId = action.product.productId;
            let lottery_set_productOptionArray = action.product.productOptionArray?action.product.productOptionArray: productDataset.find(dt => dt.id === lottery_set_productId).productOptionArray;
            
            let find = lottery_set_productOptionArray.find(dt => dt.id === 81);
            if (find) {
                let index = lottery_set_productOptionArray.indexOf(find);
                lottery_set_productOptionArray.splice(index, 1);
            }
            let lottery_set_productOptionId = action.product.productOptionArray?action.product.productOptionArray[0].id: lottery_set_productOptionArray[0].id;
            let lottery_set_productOptionKenoTypeArray = lottery_set_productId === 6 ? getProductOptionKenoTypeArray(lottery_set_productOptionId) : [];
            
            let lottery_set_productOptionKenoTypeId = lottery_set_productOptionKenoTypeArray.length ? lottery_set_productOptionKenoTypeArray[0].id : 0;
            
            let numberSelect=action.product.productOptionArray?action.product.productOptionArray[0].select: getNumberSelect(lottery_set_productId, lottery_set_productOptionId);

            let value=[];
            if(action.product.numberTicket){
                value= Array(action.product.numberTicket).fill({
                    "number": [],
                    "price": getPriceArray(lottery_set_productId)[0].id
                })
            }
            else{
                value=Array(6).fill({
                    "number": [],
                    "price": [3, 4, 5, 6,12].includes(lottery_set_productId) ? getPriceArray(lottery_set_productId)[1].id : getPriceForProductOptionId(lottery_set_productId, lottery_set_productOptionId)
                });
            }
            //baokeno init
            if(action.product.isKenoBao){
               lottery_set_productOptionArray=  lottery_set_productOptionArray.filter(t=>t.id!==71&&t.id!==82 );
               lottery_set_productOptionId=lottery_set_productOptionArray[0].id;
               lottery_set_productOptionKenoTypeId=3;
               numberSelect=getNumberSelect(6,lottery_set_productOptionKenoTypeId, {
                kenoType: true,
                productOptionId: lottery_set_productOptionId
            });
            }
            let lottery_set_product = {
                "productId": lottery_set_productId,
                "productOptionArray": lottery_set_productOptionArray,
                "productOptionId": lottery_set_productOptionId,
                "productOptionKenoTypeArray": lottery_set_productOptionKenoTypeArray,
                "productOptionKenoTypeId": lottery_set_productOptionKenoTypeId,
                "numberSelect": numberSelect,
                "startNumber":action.product.productOptionArray?action.product.productOptionArray[0].startNumber : getStartNumber(lottery_set_productId, lottery_set_productOptionId),
                "endNumber": action.product.productOptionArray?action.product.productOptionArray[0].endNumber : getEndNumber(lottery_set_productId, lottery_set_productOptionId),
                "priceArray": getPriceArray(lottery_set_productId),
                "termArray": action.product.termArray,
                "value": value,
                "term": [action.product.termArray[0].termValue],
            };
            return lottery_set_product;
        case types.LOTTERY_CHANGE_PRICE:
            let copy_price = JSON.parse(JSON.stringify(state));
            copy_price.price = action.price;
            return copy_price;
            case types.CHANGE_TC:{
                let copy_number = JSON.parse(JSON.stringify(state));
                copy_number.value = action.value;
                copy_number.isTC=action.isTC
                return copy_number;
            }
        default:
            return state;
    }
}
